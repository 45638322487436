import React, { useContext } from "react";
import TabLayout from "../../../TabLayout/TabLayout";
import Dashboard from "./Dashboard";
import AuthContext from "../../../../hooks/useAuth";
import OrganizationDashboard from "./OrganizationDashboard";
import { ThemePreferenceContext, themesMap } from "../../../../index";
import OrganizationDashboardSuperAdmin from "../SuperAdmin/dashboard/OrganizationDashboardSuperAdmin";

function HomeWrapper() {
  const auth = useContext(AuthContext);
  const role = auth.role;
  let roleFlag = role === "admin" || "super_admin";
  const { currentTheme } = useContext(ThemePreferenceContext);
  const menuItems = [
    {
      name: "dashboard",
      label: "My Dashboard",
      content: <Dashboard />,
    },
    {
      name: "organization",
      label: "Organization Dashboard",
      content: <OrganizationDashboard />,
      disableClick: !roleFlag,
    },
  ];

  return (
    <div data-theme={currentTheme} style={{ marginTop: "2rem" }}>
      <TabLayout menuItems={menuItems} defaultTab="dashboard"></TabLayout>
    </div>
  );
}

export default HomeWrapper;
