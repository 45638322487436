import React, { useContext, useState, useEffect, useRef } from "react";
import TextField from "@mui/material/TextField";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../../../../axios";
import AuthContext from "../../../../../hooks/useAuth";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import AndroidIcon from "@mui/icons-material/Android";
import { Typography } from "@mui/material";
import "./OrganizationDashboard.css";
import "../../Home/Dashboard.css";
import { StylesProvider } from "@material-ui/core/styles";
import { format, parseISO, isValid } from 'date-fns';
import { ThemePreferenceContext } from "../../../../../index";
import SessionMain from "../../Sessions/Sessions";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import AppleIcon from "@mui/icons-material/Apple";

var mapObj = {
    com: " ",
    ".oneplus": " ",
    ".qualcomm": " ",
    ".android": " ",
    ".display": " ",
    ".google": " ",
    ".tools": " ",
    ".internal": " ",
    ".emulation": " ",
    ".network": " ",
    ".dragonfistztamilan": " ",
};

export default function OrganizationDashboardSuperAdmin() {
    const { currentTheme, setCurrentTheme } = useContext(ThemePreferenceContext);
    const auth = useContext(AuthContext);
    const role = auth.role;
    const currentUserId = JSON.parse(
        sessionStorage.getItem("currentSessionData")
    );
    const uid = auth.id || currentUserId;
    const [sessionNoteTitle, setsessionNoteTitle] = useState(null);
    const [selectedOption, setSelectedOption] = useState("");
    const options = [
        "CPU Usage",
        "GPU Usage",
        "Avg Memory",
        "FPS",
        "Downloaded Data",
        "Uploaded Data",
        "AppPowerData",
    ];
    const handleSelectOption = (event) => {
        if (
            event &&
            typeof event === "object" &&
            "target" in event &&
            "value" in event.target
        ) {
            console.log("Selected Option Value:", event.target.value);
            setSelectedOption(event.target.value);
        } else {
            console.log("Selected Option Event:", event);
            setSelectedOption(event);
        }
    };
    const [Firstsessiondata, SetFirstsessiondata] = React.useState([]);
    const [Secondsessiondata, SetSecondsessiondata] = React.useState([]);
    const [selecteditem, setSelecteditem] = React.useState("yellow");
    const [ApplicationActive, setApplicationActive] = useState(false);
    const [DevicesActive, setDevicesActive] = useState(false);
    const [UserActive, setUserActive] = useState(false);
    const [SessionsActive, setSessionsActive] = useState(false);
    const [DateActive, setDateActive] = useState(false);
    const [User, setUser] = useState("");
    const [application, setApplication] = useState("");
    const [device, setDevices] = useState("");
    const [sessions, setSessions] = useState("");
    const [userName, setUserName] = useState("");
    const [notes, setNotes] = useState("");
    const [date, setDate] = useState("");
    const [FirstUserdata, SetFirstUserdata] = React.useState([]);
    const [Firstdevicedata, SetFirstdevicedata] = React.useState([]);
    const [Seconddevicedata, SetSeconddevicedata] = React.useState([]);
    const [selectedUseritem, setSelectedUseritem] = React.useState("yellow");
    const [selectedDeviceitem, setSelectedDeviceitem] = React.useState("yellow");
    const [selectedDevice, setSelectedDevice] = React.useState("");
    const [selectedsessionitem, setSelectedApplicationitem] = React.useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [AllSessionsActive, setAllSessionsActive] = useState(false);
    const [Allsessions, setAllsessions] = useState("");
    const [allSession, setAllSession] = useState([]);
    const [topPerformer, setTopPerformer] = useState([]);
    const [pageScroll, setPageScroll] = useState(1);
    const sortedSessions = allSession?.sort(
        (a, b) => new Date(b.session_date) - new Date(a.session_date)
    );
    //organization filter
    // New states for organization filter
    const [organizations, setOrganizations] = useState([]);
    const [selectedOrgs, setSelectedOrgs] = useState([]);
    const [orgSearchTerm, setOrgSearchTerm] = useState('');
    const [isOrgDropdownOpen, setIsOrgDropdownOpen] = useState(false);
    var randomNum = Math.floor(Math.random() * (99 - 10 + 1)) + 10;

    const initialValue = {
        org_id: randomNum,
        name: "",
        email: "",
        password: null,
        phone_number: "1234509876",
        role: "",
        access_end_date: "",
        access_start_date: "",
    };

    const [formData, setFormData] = useState(initialValue);

    // Fetch organizations
    useEffect(() => {
        axios
            .get("/organization", {
                headers: {
                    Authorization: `Bearer ${auth.token}`,
                    "Content-Type": "application/json",
                },
            })
            .then((res) => {
                setOrganizations(res.data.data);
            });
    }, [auth.token]);

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOrgDropdownOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    // Filter organizations
    const filteredOrganizations = organizations
        .filter((org) => org.status_org !== "N")
        .filter((org) =>
            org.org_name.toLowerCase().includes(orgSearchTerm.toLowerCase())
        );

    const toggleOrganization = (org) => {
        setSelectedOrgs(prev =>
            prev.includes(org)
                ? prev.filter(o => o !== org)
                : [...prev, org]
        );
    };

    const [calls, setcall] = useState(uid);

    React.useEffect(() => {
        let isMount = true;
        axios
            .get("/users", {
                headers: {
                    Authorization: `Bearer ${auth.token}`,
                },
            })
            .then((res) => {
                if (isMount) {
                    SetFirstUserdata(res.data.data);
                }
            })
            .catch((err) => { });
        return () => {
            isMount = false;
        };
    }, []);

    const dropdownRef = useRef(null);

    const handleOutsideClick = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setUserActive(false);
            setDevicesActive(false);
            setApplicationActive(false);
            setSessionsActive(false);
            setDateActive(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleOutsideClick);
        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    React.useEffect(() => {
        let isMount = true;
        if (uid && startDate && endDate) {
            axios
                .get("/devices", {
                    params: {
                        userId: uid,
                        fromDate: startDate,
                        toDate: endDate,
                    },
                    headers: {
                        Authorization: `Bearer ${auth.token}`,
                    },
                })
                .then((res) => {
                    if (res.status == 200) {
                        SetFirstdevicedata(res.data.data);
                        global.device_name = res.data.device_name;
                        SetSeconddevicedata(null);
                        SetFirstsessiondata(null);
                    } else {
                        SetFirstdevicedata(null);
                    }
                })
                .catch((err) => {
                    SetFirstdevicedata(null);
                    SetSeconddevicedata(null);
                    SetFirstsessiondata(null);
                });
        }
        return () => {
            isMount = false;
        };
    }, [startDate, endDate]);

    function formatDate(dateString) {
        if (!dateString) {
            return "";
        }
        const date = parseISO(dateString);
        if (!isValid(date)) {
            return "";
        }
        return format(date, "dd-MMM-yyyy");
    }

    //Display available User
    function singleUserItem(e, data, id) {
        setSelectedUseritem(id);
        axios
            .get("/users", {
                headers: {
                    Authorization: `Bearer ${auth.token}`,
                },
            })
            .then((res) => { });
    }

    //Display available Application
    function singleDeviceItem(e, data, id) {
        setSelectedDeviceitem(id);
        axios
            .get("/applications", {
                params: {
                    deviceId: data.device_id,
                    userId: uid,
                },
                headers: {
                    Authorization: `Bearer ${auth.token}`,
                },
            })
            .then((res) => {
                SetSeconddevicedata(res.data.data);
            });
    }

    //All Session function
    function singleApplicationItem(e, data, id) {
        setSelectedApplicationitem(id);
        axios
            .get("/allSessions", {
                params: {
                    deviceId: data.device_id,
                    userId: uid,
                    appName: data.app_name,
                    fromDate: startDate,
                    toDate: endDate,
                },
                headers: {
                    Authorization: `Bearer ${auth.token}`,
                },
            })
            .then((res) => {
                SetFirstsessiondata(res.data.data);
            })
            .catch((err) => {
                toast.error("Oops! No Sessions!", {
                    position: toast.POSITION.BOTTOM_CENTER,
                });
            });
    }

    useEffect(() => {
        Sessions(pageScroll);
        TopFerformer();
    }, [calls]);

    function Sessions(pageNum) {
        axios
            .get("/orgRecentSessions", {
                params: {
                    userId: calls,
                    page: pageNum,
                    size: 50,
                },
                headers: {
                    Authorization: `Bearer ${auth.token}`,
                },
            })
            .then((res) => {
                if (res.status === 204) {
                    setAllSession([]);
                } else {
                    if (res?.data?.data?.length) {
                        setAllSession((prevRes) => [...prevRes, ...res.data.data]);
                    }
                    singleSessionItem();
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 404) {
                    console.log(error);
                }
            });
    }

    function TopFerformer() {
        axios
            .get("/topPerformers", {
                params: {
                    userId: calls,
                },
                headers: {
                    Authorization: `Bearer ${auth.token}`,
                },
            })
            .then((res) => {
                if (res.status === 204) {
                    setTopPerformer([]);
                } else {
                    setTopPerformer(res.data.data);
                    singleSessionItem();
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 404) {
                    setTopPerformer([]);
                }
            });
    }

    //Display specfic session details
    function singleSessionItem(e, data, id) {
        setSelecteditem(id);
        axios
            .get("/sessionDetails", {
                params: {
                    DeviceId: data.device_id,
                    appName: data.app_name,
                    userId: uid,
                    sessionId: data.session_id,
                    createdDate: data.session_date,
                },
                headers: {
                    Authorization: `Bearer ${auth.token}`,
                },
            })
            .then((res) => {
                SetSecondsessiondata(res.data.data[0]);
                sessionStorage.setItem(
                    "currentSessionData",
                    JSON.stringify(res.data.data[0])
                );
                global.sessionname = data.sessionname;
                global.deviceid = data.device_id;
                global.userid = data.user_id;
                global.appname = data.app_name;
                global.sessionid = data.session_id;
                global.createdDate = data.session_date;
                setTimeout(() => {
                    setSelectedDevice(data.sessionname);
                }, 100);
            });
    }

    React.useEffect(() => {
        const sessionData = window.sessionStorage.getItem("sessiondata");
        const savedValues = JSON.parse(sessionData);
        SetFirstdevicedata(savedValues?.Firstdevicedata);
        setsessionNoteTitle(savedValues?.sessionNoteTitle);
        SetSeconddevicedata(savedValues?.Seconddevicedata);
        SetFirstsessiondata(savedValues?.Firstsessiondata);
        SetSecondsessiondata(savedValues?.Secondsessiondata);
        setNotes(savedValues?.notes);
        setStartDate(savedValues?.startDate);
        setEndDate(savedValues?.endDate);
        setDevices(savedValues?.device);
        setApplication(savedValues?.application);
        setSessions(savedValues?.sessions);
        setUserName(savedValues?.user_name);
        setUser(savedValues?.User);
    }, []);

    React.useEffect(() => {
        const valuesToSave = {
            Firstdevicedata,
            sessionNoteTitle,
            Seconddevicedata,
            Firstsessiondata,
            Secondsessiondata,
            date,
            device,
            notes,
            application,
            sessions,
            startDate,
            endDate,
            User,
            userName,
        };
        // window.sessionStorage.setItem("sessiondata", JSON.stringify(valuesToSave));
    });

    const handleScroll = (e) => {
        const bottom =
            Number((e.target.scrollHeight - e.target.scrollTop).toFixed(0)) -
            e.target.clientHeight <
            50;
        if (bottom) {
            setPageScroll((prevRes) => prevRes + 1);
        }
    };

    useEffect(() => {
        if (pageScroll > 1) {
            Sessions(pageScroll);
        }
    }, [pageScroll]);

    const remainingPercentage = "NA";
    const usedPercentage = "NA";
    const EndsInHours = "NA";

    //latest organizations
    const [firstOrg, setFirstOrg] = useState("ABC")
    const [secondOrg, setSecondtOrg] = useState("Capricon")
    const [thirdOrg, setthirdOrg] = useState("Email test")



    return (
        <>
            <div
                style={{
                    width: "70vw",
                    position: "fixed",
                    height: "65vh",
                }}
            >
                {/* Organization Filter Dropdown */}
                <div style={{ position: 'relative' }} ref={dropdownRef}>
                    <div
                        onClick={() => setIsOrgDropdownOpen(!isOrgDropdownOpen)}
                        style={{
                            backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                            color: currentTheme === "dark" ? "white" : "black",
                            border: currentTheme === "dark" ? "1px solid #646667" : "1px solid #DFDFDF",
                            borderRadius: "8px",
                            padding: "8px 12px",
                            cursor: "pointer",
                            width: "200px",
                            height: "30px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between"
                        }}
                    >
                        <span style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            fontSize: "12px"
                        }}>
                            {selectedOrgs.length === 0
                                ? "Select Organizations"
                                : selectedOrgs.length === 1
                                    ? selectedOrgs[0].org_name
                                    : `${selectedOrgs.length} Selected`}
                        </span>
                        <span>▼</span>
                    </div>

                    {isOrgDropdownOpen && (
                        <div
                            style={{
                                position: "absolute",
                                top: "100%",
                                left: 0,
                                zIndex: 1000,

                                backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                                border: currentTheme === "dark" ? "1px solid #646667" : "1px solid #DFDFDF",
                                borderRadius: "8px",
                                width: "200px",
                                boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
                                marginTop: "4px"
                            }}
                        >
                            <TextField
                                placeholder="Search organizations..."
                                value={orgSearchTerm}
                                onChange={(e) => setOrgSearchTerm(e.target.value)}
                                style={{ margin: "8px", width: "calc(100% - 30px)" }}
                                size="small"
                                variant="outlined"
                                InputProps={{
                                    style: { height: "30px", fontSize: "14px", padding: "5px 10px" }
                                }}
                            />
                            <div style={{
                                maxHeight: "200px",
                                overflowY: "auto",
                                padding: "8px"
                            }}>
                                {filteredOrganizations.map((org) => (
                                    <div
                                        key={org.org_id}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            padding: "8px",
                                            cursor: "pointer",
                                            backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                                            color: currentTheme === "dark" ? "white" : "black",
                                            fontSize: "12px"
                                        }}
                                        onClick={() => toggleOrganization(org)}
                                    >
                                        <input
                                            type="checkbox"
                                            checked={selectedOrgs.includes(org)}
                                            onChange={() => { }}
                                            style={{ marginRight: "8px" }}
                                        />
                                        <span>{org.org_name}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>

                <main>
                    <div style={{ margin: 10, flexDirection: "row", display: "flex" }}>
                        <div
                            style={{
                                display: "flex",
                                position: "relative",
                                marginTop: "1%",
                                justifyContent: "space-between",
                                marginLeft: "-1vw",
                            }}
                        >
                            <div style={{ textAlign: "start" }}>
                                <p
                                    style={{
                                        color: currentTheme === "dark" ? "white" : "black",
                                        paddingBottom: "3px",
                                        marginBottom: "5px",
                                    }}
                                    className="cards-title"
                                >
                                    Top Performers
                                </p>
                                <div
                                    style={{
                                        width: "15vw",
                                        height: "25vh",
                                        backgroundColor:
                                            currentTheme === "dark" ? "#292E33" : "white",
                                        borderRadius: "10px",
                                        boxShadow: "0px 3px 6px #0000000A",
                                        display: "flex",
                                        flexDirection: "column",
                                        overflowY: "auto",
                                        overflowX: "hidden",
                                        scrollBehavior: "smooth",
                                    }}
                                    className="ScrollBars"
                                >
                                    {topPerformer.length > 0
                                        ? topPerformer.map((data, i) => {
                                            return (
                                                <div
                                                    style={{
                                                        color:
                                                            currentTheme === "dark" ? "white" : "black",
                                                        fontWeight: 600,
                                                        textTransform: "capitalize",
                                                    }}
                                                    className="card3-header"
                                                >
                                                    {data.name}
                                                    <br />
                                                    <span
                                                        style={{
                                                            color:
                                                                currentTheme === "dark"
                                                                    ? "#99999F"
                                                                    : "#818E94",
                                                            fontWeight: 400,
                                                            textTransform: "capitalize",
                                                        }}
                                                        className="card3-content"
                                                    >
                                                        {data.session_count} Sessions | 32 Hours
                                                    </span>
                                                    {i < topPerformer.length - 1 && (
                                                        <hr
                                                            style={{
                                                                border: "none",
                                                                borderBottom:
                                                                    currentTheme === "dark"
                                                                        ? "0.5px solid #A2B1C2"
                                                                        : "1px solid #e6e6e6",
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                            );
                                        })
                                        : <div style={{
                                            fontWeight: "600",
                                            textAlign: "center",
                                            marginTop: "10%",
                                            fontSize: "12px",
                                            color: currentTheme === "dark" ? "#FFFFFF" : "black",
                                        }}>Opps..! No Sessions!</div>}
                                </div>
                            </div>
                            <div style={{ textAlign: "start", marginLeft: "1vw" }}>
                                <p
                                    style={{
                                        color: currentTheme === "dark" ? "white" : "black",
                                        paddingBottom: "3px",
                                        marginBottom: "5px",
                                    }}
                                    className="cards-title"
                                >
                                    Quick Stats
                                </p>
                                <div
                                    style={{
                                        width: "23vw",
                                        height: "25vh",
                                        backgroundColor:
                                            currentTheme === "dark" ? "#292E33" : "white",
                                        borderRadius: "10px",
                                        boxShadow: "0px 3px 6px #0000000A",
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <ListItemText>
                                        <div
                                            style={{
                                                display: "flex",
                                            }}
                                        >
                                            <Typography
                                                variant="body"
                                                style={{
                                                    fontWeight: "600",
                                                    marginLeft: "15px",
                                                    color: currentTheme === "dark" ? "#FFFFFF" : "black",
                                                }}
                                                className="org-fonts"
                                            >
                                                Org.Overall Sessions
                                                <span
                                                    style={{
                                                        fontWeight: "600",
                                                        color:
                                                            currentTheme === "dark" ? "#99999F" : "#818E94",
                                                    }}
                                                    className="org-content1"
                                                >
                                                    {sortedSessions?.length > 0
                                                        ? sortedSessions[0]?.total_sessions
                                                        : "-"}
                                                </span>
                                            </Typography>

                                            <Typography
                                                variant="body"
                                                style={{
                                                    fontWeight: "600",
                                                    marginLeft: "5.5vw",
                                                    color: currentTheme === "dark" ? "#FFFFFF" : "black",
                                                }}
                                                className="org-fonts"
                                            >
                                                Org.Tested Apps
                                                <span
                                                    style={{
                                                        fontWeight: "600",
                                                        color:
                                                            currentTheme === "dark" ? "#99999F" : "#818E94",
                                                    }}
                                                    className="org-content2"
                                                >
                                                    {sortedSessions?.length > 0
                                                        ? sortedSessions[0]?.total_app_count
                                                        : "-"}
                                                </span>
                                            </Typography>
                                        </div>
                                        <hr
                                            style={{
                                                border: "none",
                                                borderBottom:
                                                    currentTheme === "dark"
                                                        ? "0.5px solid #A2B1C2"
                                                        : "1px solid #e6e6e6",
                                                marginTop: "8vh",
                                            }}
                                        />
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Typography
                                                variant="body"
                                                style={{
                                                    fontWeight: "600",
                                                    marginLeft: "15px",
                                                    marginTop: "10px",
                                                    color: currentTheme === "dark" ? "#FFFFFF" : "black",
                                                }}
                                                className="org-fonts"
                                            >
                                                Ends In
                                                <span
                                                    style={{
                                                        fontWeight: "600",
                                                        color:
                                                            currentTheme === "dark" ? "#99999F" : "#818E94",
                                                        marginLeft: "50px"
                                                    }}
                                                    className="org-content3"
                                                >

                                                    {`${EndsInHours}`}

                                                </span>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        width: "20%",
                                                    }}
                                                    className="orgprog-label"
                                                >
                                                    <div
                                                        style={{ marginRight: "10px", marginTop: "0.5vh" }}
                                                    >
                                                        <span style={{ marginLeft: "-0.6vw" }}>
                                                            Remaining
                                                        </span>
                                                        <div style={{ width: "3vw" }}>
                                                            <CircularProgressbar
                                                                styles={buildStyles({
                                                                    pathColor: "#4779EF",
                                                                    trailColor: "lightgrey",
                                                                    textColor:
                                                                        currentTheme === "dark" ? "white" : "black",
                                                                    textSize: "25px",
                                                                })}
                                                                value={remainingPercentage}
                                                                text={`${remainingPercentage}`}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{ marginLeft: "10px", marginTop: "0.5vh" }}
                                                    >
                                                        <span style={{ marginLeft: "0.5vw" }}>Used</span>
                                                        <div style={{ width: "3vw" }}>
                                                            <CircularProgressbar
                                                                styles={buildStyles({
                                                                    pathColor: "#FEC400",
                                                                    trailColor: "lightgrey",
                                                                    textColor:
                                                                        currentTheme === "dark" ? "white" : "black",
                                                                    textSize: "25px",
                                                                })}
                                                                value={usedPercentage}
                                                                text={`${usedPercentage}`}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Typography>
                                        </div>
                                    </ListItemText>
                                </div>
                            </div>
                            <div style={{ textAlign: "start", marginLeft: "1vw" }}>
                                <p
                                    style={{
                                        color: currentTheme === "dark" ? "white" : "black",
                                        paddingBottom: "3px",
                                        marginBottom: "5px",
                                    }}
                                    className="cards-title"
                                >
                                    Session Hour Summary
                                </p>
                                <div
                                    style={{
                                        width: "15vw",
                                        height: "25vh",
                                        backgroundColor:
                                            currentTheme === "dark" ? "#292E33" : "white",
                                        borderRadius: "10px",
                                        boxShadow: "0px 3px 6px #0000000A",
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <div
                                        style={{
                                            color: currentTheme === "dark" ? "white" : "black",
                                        }}
                                        className="card3-header"
                                    >
                                        Current Plan
                                        <br />
                                        <span
                                            style={{
                                                color: currentTheme === "dark" ? "#99999F" : "#818E94",
                                            }}
                                            className="card3-content"
                                        >
                                            Post Paid Hours
                                        </span>
                                        <button
                                            style={{
                                                height: "3vh",
                                                width: "3vw",
                                                backgroundColor: "#4779EF",
                                                color: "#FFFFFF",
                                                borderRadius: "2px",
                                                marginTop: "-25px",
                                                cursor: "pointer",
                                                border: "none",
                                                outline: "none",
                                                marginLeft: "4vw",
                                            }}
                                        >
                                            NA
                                        </button>
                                    </div>
                                    <hr
                                        style={{
                                            border: "none",
                                            borderBottom:
                                                currentTheme === "dark"
                                                    ? "0.5px solid #A2B1C2"
                                                    : "1px solid #e6e6e6",
                                        }}
                                    />
                                    <div
                                        style={{
                                            color: currentTheme === "dark" ? "white" : "black",
                                        }}
                                        className="card3-header"
                                    >
                                        Budget Spend
                                        <br />
                                        <span
                                            style={{
                                                color: currentTheme === "dark" ? "#99999F" : "#818E94",
                                            }}
                                            className="card3-content"
                                        >
                                            NA
                                        </span>
                                    </div>
                                    <hr
                                        style={{
                                            border: "none",
                                            borderBottom:
                                                currentTheme === "dark"
                                                    ? "0.5px solid #A2B1C2"
                                                    : "1px solid #e6e6e6",
                                        }}
                                    />
                                    <div
                                        style={{
                                            color: currentTheme === "dark" ? "white" : "black",
                                        }}
                                        className="card3-header"
                                    >
                                        Hours Spent
                                        <br />
                                        <span
                                            style={{
                                                color: currentTheme === "dark" ? "#99999F" : "#818E94",
                                            }}
                                            className="card3-content"
                                        >
                                            31:00 / 60:00
                                        </span>
                                    </div>
                                </div>
                            </div>

                            {/* performance trends removed */}


                            <div
                                style={{
                                    marginLeft: "1vw",
                                    width: "40vw",
                                    position: "relative",
                                    fontSize: "12px",
                                    fontWeight: "600",
                                }}
                            >
                                <div>
                                    <p
                                        style={{
                                            color: currentTheme === "dark" ? "white" : "black",
                                            marginBottom: "5px",
                                        }}
                                        className="cards-title"
                                    >
                                        Org.Recent Sessions
                                    </p>
                                    <div
                                        value={currentTheme}
                                        onChange={(e) => {
                                            setCurrentTheme(e.target.value);
                                        }}
                                        style={{
                                            display: AllSessionsActive != 404 ? "block" : "none",
                                            marginLeft: "-0.5vw",
                                            width: "27.5vw",
                                            backgroundColor:
                                                currentTheme === "dark" ? "#292E33" : "white",
                                            borderRadius: "8px",
                                            height: "72vh",
                                            overflowY: "auto",
                                            overflowX: "hidden",
                                            scrollBehavior: "smooth",
                                            boxShadow: "0px 2px 6px #0000000A",
                                        }}
                                        className="ScrollBars"
                                        onScroll={(e) => handleScroll(e)}
                                    >
                                        {sortedSessions?.length > 0 ? (
                                            sortedSessions.map((data, i) => (
                                                <div
                                                    key={i}
                                                    onClick={(e) => {
                                                        setAllsessions(e.target.textContent);
                                                        setAllSessionsActive(!AllSessionsActive);
                                                        singleSessionItem(e, data, i);
                                                    }}
                                                    style={{
                                                        color: currentTheme === "dark" ? "white" : "black",
                                                        fontSize: "20px",
                                                        borderRadius: "5px",
                                                        margin: "5px",
                                                        width: "95%",
                                                    }}
                                                >
                                                    <ListItem>
                                                        {data.device_name === "apple" ? (
                                                            <AppleIcon
                                                                sx={{ fontSize: 40, marginLeft: "-15px" }}
                                                            />
                                                        ) : (
                                                            <AndroidIcon
                                                                sx={{ fontSize: 40, marginLeft: "-15px" }}
                                                            />
                                                        )}
                                                        <ListItemText>
                                                            <Typography
                                                                variant="body"
                                                                style={{
                                                                    fontWeight: "600",
                                                                    marginLeft: "10px",
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis",
                                                                    whiteSpace: "nowrap",
                                                                    cursor: "pointer",
                                                                    textTransform: "capitalize"
                                                                }}
                                                                title={data?.sessionname}
                                                                className="org-appname"
                                                            >
                                                                {data?.sessionname}
                                                            </Typography>
                                                            <br />
                                                            <div
                                                                style={{
                                                                    marginLeft: "10px",
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis",
                                                                    whiteSpace: "nowrap",
                                                                    cursor: "pointer",
                                                                    marginBottom: "-25px"
                                                                }}
                                                                title={data?.app_name}
                                                                className="org-appname"
                                                            >
                                                                {data?.app_name}
                                                            </div>
                                                            <br />
                                                            <Typography
                                                                style={{
                                                                    fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
              'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
              sans-serif`,
                                                                    marginTop: "2px",
                                                                    color:
                                                                        currentTheme === "dark"
                                                                            ? "#A2B1C2"
                                                                            : "black",
                                                                }}
                                                                id="scroll-orgfonts"
                                                            >
                                                                Created Date:{" "}
                                                                <span
                                                                    style={{
                                                                        color: "#4779EF",
                                                                        fontWeight: "600",
                                                                        overflow: "hidden",
                                                                        textOverflow: "ellipsis",
                                                                        whiteSpace: "nowrap",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    title={data?.session_date}
                                                                    id="scroll-fonts"
                                                                >
                                                                    {data?.session_date}
                                                                </span>
                                                            </Typography>
                                                            <Typography
                                                                style={{
                                                                    fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
              'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
              sans-serif`,
                                                                    color:
                                                                        currentTheme === "dark"
                                                                            ? "#A2B1C2"
                                                                            : "black",
                                                                }}
                                                                id="scroll-fonts3"
                                                            >
                                                                Tested Device:{" "}
                                                                <span
                                                                    style={{
                                                                        textTransform: "capitalize",
                                                                        color: "#4779EF",
                                                                        fontWeight: "600",
                                                                        overflow: "hidden",
                                                                        textOverflow: "ellipsis",
                                                                        whiteSpace: "nowrap",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    title={data?.device_name}
                                                                    id="scroll-fonts"
                                                                >
                                                                    {data?.device_name}
                                                                </span>
                                                            </Typography>
                                                        </ListItemText>
                                                    </ListItem>
                                                    {i < sortedSessions.length - 1 && (
                                                        <hr
                                                            style={{
                                                                border: "none",
                                                                borderBottom:
                                                                    currentTheme === "dark"
                                                                        ? "0.5px solid #A2B1C2"
                                                                        : "1px solid #e6e6e6",
                                                                margin: "5px 0",
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                            ))
                                        ) : (
                                            <div
                                                style={{
                                                    textAlign: "center",
                                                    padding: "20px",
                                                    fontSize: "20px",
                                                    fontWeight: "600",
                                                    color: currentTheme === "dark" ? "white" : "black",
                                                }}
                                            >
                                                {AllSessionsActive == 404
                                                    ? "No Recent sessions"
                                                    : "No Recent logs!"}
                                            </div>

                                        )}
                                        <div>
                                            <SessionMain
                                                User_id={uid}
                                                currentTheme={currentTheme}
                                                selectedOption={selectedOption}
                                            />
                                            <ToastContainer
                                                toastStyle={{
                                                    marginTop: "4rem",
                                                    borderRadius: "10px",
                                                    backgroundColor: "white",
                                                    alignContent: "center",
                                                    height: 50,
                                                    margin: 10,
                                                    width: 300,
                                                    closeOnClick: true,
                                                    textAlign: "center",
                                                }}
                                                closeButton={false}
                                                hideProgressBar={false}
                                                autoClose={2000}
                                                position="top-center"
                                                className="toast-container"
                                                toastClassName="dark-toast"
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                
                    <div
            style={{
                width: "70vw",
                position: "fixed",
                height: "65vh",
            }}
        >
            <div style={{ position: 'relative',marginRight: "1px", }}>
                {/* Latest Organizations Container */}
                <div
                    style={{
                        backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                        color: currentTheme === "dark" ? "white" : "black",
                        border: currentTheme === "dark" ? "1px solid #646667" : "1px solid #DFDFDF",
                        borderRadius: "8px",
                        padding: "16px",
                        width: "700px",
                        marginTop:"200px",
                        marginLeft: "-1vw",
                    }}
                >
                    <div style={{ marginBottom: "16px" }}>
                        <span style={{
                            fontSize: "14px",
                            fontWeight: "500"
                        }}>
                            Latest Organizations
                        </span>
                    </div>

                    {/* First Organization */}
                    <div
                        style={{
                            padding: "8px 12px",
                            marginBottom: "8px",
                            backgroundColor: currentTheme === "dark" ? "#363b41" : "#f5f5f5",
                            borderRadius: "6px",
                        }}
                    >
                        <span style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            fontSize: "12px"
                        }}>
                            {firstOrg}
                        </span>
                    </div>

                    {/* Second Organization */}
                    <div
                        style={{
                            padding: "8px 12px",
                            marginBottom: "8px",
                            backgroundColor: currentTheme === "dark" ? "#363b41" : "#f5f5f5",
                            borderRadius: "6px",
                        }}
                    >
                        <span style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            fontSize: "12px"
                        }}>
                            {secondOrg}
                        </span>
                    </div>

                    {/* Third Organization */}
                    <div
                        style={{
                            padding: "8px 12px",
                            backgroundColor: currentTheme === "dark" ? "#363b41" : "#f5f5f5",
                            borderRadius: "6px",
                        }}
                    >
                        <span style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            fontSize: "12px"
                        }}>
                            {thirdOrg}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        </div>

                </main>
                
            </div>
        </>
    );
}
