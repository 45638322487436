import React, {

    useState,
    useEffect,
    useMemo,
    useContext,
    useCallback,
  } from "react";
  import { AgGridReact } from "ag-grid-react";
  import "ag-grid-community/dist/styles/ag-grid.css";
  import "ag-grid-community/dist/styles/ag-theme-alpine.css";
  import { Grid } from "@material-ui/core";
  import { TextField, InputAdornment, Typography } from "@mui/material";
  import SearchIcon from "@mui/icons-material/Search";
  import axios from "../../../../axios/index";
  import { ThemePreferenceContext } from "../../../../index";
  import AuthContext from "../../../../hooks/useAuth";
  import { ToastContainer } from "react-toastify";
  import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
  import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
  import Chip from "@mui/material/Chip";
  import "react-toastify/dist/ReactToastify.css";
  import "./OrgUsage.css"
  
  function OrgUsagePage() {
    const [users, setUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const { currentTheme } = useContext(ThemePreferenceContext);
    const auth = useContext(AuthContext);
  
    useEffect(() => {
      getUsers();
    }, []);
  
    // Fetching user data from the new API response
    const getUsers = () => {
      axios
        .get("/organization_info", {
          headers: {
            Authorization: `Bearer ${auth.token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setUsers(res.data.data); // Update with new response data
        })
        .catch((error) => {
          console.error("Error fetching organization information:", error);
        });
    };
  
    // Filtering and preparing the row data based on new API format
    const rowData = users?.map((data) => ({
      id: data?.org_id,
      org_name: data?.org_name,
      total_seconds: data?.total_seconds,
      organization_count: data?.organization_count,
      latest_status: data?.latest_status,
      latest_invoice_id: data?.latest_invoice_id,
      latest_tax_id: data?.latest_tax_id,
    }));
  
    // Filter the row data based on the search term
    const filteredData = rowData.filter((data) =>
      data.org_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  
    const capitalizeFirstLetter = (value) => {
      if (!value) return "";
      return value
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(" ");
    };
  
    const defaultColDef = useMemo(
      () => ({
        resizable: true,
        sortable: true,
        cellStyle: { fontSize: "15px", width: 250 },
      }),
      []
    );
  
    const columnDefs = [
      {
        headerName: "ID",
        field: "id",
        flex: 1,
        maxWidth: 100,
        unSortIcon: true,
        // valueFormatter: (params) => capitalizeFirstLetter(params.value),
      },
      {
        headerName: "Organization Name",
        field: "org_name",
        flex: 1,
        minWidth: 200,
        maxWidth: 500,
        unSortIcon: true,
        valueFormatter: (params) => capitalizeFirstLetter(params.value),
      },
      {
        headerName: "Total Hours Spend",
        field: "total_seconds",
        flex: 1.5,
        maxWidth: 200,
        unSortIcon: true,
        valueFormatter: (params) => {
          // Convert total_seconds to hours and minutes
          const seconds = params.value;
          if (seconds != null && !isNaN(seconds)) {
            const hours = Math.floor(seconds / 3600);
            const minutes = Math.floor((seconds % 3600) / 60);
            return `${hours} hr ${minutes} min`; // Display hours and minutes
          }
          return "0 hr 0 min"; // Default if no valid seconds value
        },
      },
      //   {
      //     headerName: "Date Joined",
      //     field: "created_at",
      //     flex: 1,
      //     maxWidth: 700,
      //     unSortIcon: true,
      //   },
      {
        headerName: "No of Users",
        field: "organization_count",
        flex: 1,
        maxWidth: 200,
        unSortIcon: true,
      },
      {
        headerName: "Invoice Status",
        field: "invoice_status",
        cellRendererFramework: (params) => {
          const status = params.value;
  
          let chipColor = "error";
          let chipLabel = "No Data Found"; // Default label if status is null or undefined
  
          if (status === "Generated") {
            chipColor = "success";
            chipLabel = "Generated";
          } else if (status === "Not Generated") {
            chipColor = "warning";
            chipLabel = "Not Generated";
          } 
          // else if (status === null || status === "") {
          //   chipColor = "primary"; // Blue color for null or empty status
          //   chipLabel = "No Data Found";
          // }
          return (
            <Chip
              label={chipLabel}
              color={chipColor}
              style={{  cursor: "pointer",borderRadius:"5px",fontWeight:"bold" }}
              // variant="filled"
               variant="outlined"
            />
          );
        },
      },
      {
        headerName: "Tax ID",
        field: "latest_tax_id",
        flex: 1.5,
        maxWidth: 100,
        unSortIcon: true,
      },
    ];
  
    const paginationNumberFormatter = useCallback((params) => {
      // return '[' + params.value.toLocaleString() + ']';
      return params.value;
    }, []);
  
    return (
      <>
        <div
          style={{
            width: "81vw",
            marginTop: "4%",
            position: "fixed",
            // background: "skyblue",
            // height: "75vh",
          }}
        >
          <main>
            <div
              className="ag-theme-alpine"
              style={{
                height: "83vh",
                // marginTop: "%",
                backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
              }}
            >
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  margin: "0 12px",
                  paddingTop: "20px",
                }}
              >
                <div>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder={"Search Organization"}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon
                            style={{
                              color:
                                currentTheme === "dark" ? "#99999F" : "#818E94",
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      height: "40px",
                      // width: "100%",
                      // marginLeft: '9px',
                      "& input::placeholder": {
                        textOverflow: "ellipsis !important",
                        color: currentTheme === "dark" ? "#99999F" : "#818E94",
                        opacity: 1,
                      },
                      "& .MuiInputBase-root": {
                        height: "40px !important",
                        backgroundColor:
                          currentTheme === "dark" ? "#292E33" : "white",
                        borderRadius: "8px",
                        color: currentTheme === "dark" ? "#99999F" : "#000000",
                      },
                    }}
                  />
                                  
                  {/* <Typography
                      style={{
                        marginLeft: "2%",
                        fontSize: "14px",
                        marginTop: "1vh",
                        color: currentTheme === "dark" ? '#729CFF' : "#4779EF",
                        fontWeight:'600',
                      }}
                    >
                      Export  <span style={{marginLeft:'7%'}}>Print</span> 
                    </Typography> */}
                </div>
              </Grid>
              <br/>
              <div
                style={{
                  border:
                    currentTheme === "dark"
                      ? "1px solid #646667"
                      : "1px solid #DFDFDF",
                }}
                className={
                  currentTheme === "dark"
                    ? "ag-theme-alpine-dark"
                    : "ag-theme-alpine"
                }
                id="table-grid"
              >
                {/* <div style={gridStyle}> */}
                <AgGridReact
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  rowData={filteredData}
                  pagination={true}
                  paginationPageSize={10}
                  paginationNumberFormatter={paginationNumberFormatter}
                />
                {/* <Chip
                  label={chipLabel}
                  color={chipColor}
                  style={{ margin: "10px", cursor: "pointer" }}
                  variant="outlined"
                /> */}
                {/* </div> */}
              </div>
            </div>
  
            <ToastContainer
              toastStyle={{
                marginTop: "4rem",
                borderRadius: "10px",
                backgroundColor: "white",
                alignContent: "center",
                height: 50,
                margin: 10,
                width: 300,
                closeOnClick: true,
                textAlign: "center",
                position: "fixed",
                zIndex: 1050,
              }}
              closeButton={false}
              hideProgressBar={true}
              autoClose={500}
              position="top-center"
              className="toast-container"
              toastClassName="dark-toast"
            />
          </main>
        </div>
      </>
    );
  }
  
  export default OrgUsagePage;
