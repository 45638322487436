import React, { useState, useEffect } from "react";
import { useRef, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom"; // Import useParams
import indlogo from "../../asset/indlogo.png";
import background from "../../asset/background.png";
import show from "../../asset/show.png";
import hide from "../../asset/hide.png";
import "../../App.css";
import "../Login/Login.css";
import axios from "../../axios/index";
import * as Yup from "yup";
import { useFormik } from "formik";
import AuthContext from "../../hooks/useAuth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ForgetPassword = () => {
  const { token } = useParams(); 
  const [isSubmitting, setIsSubmitting] = useState(false);
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const previousToastIdRef = useRef(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  useEffect(() => {
    const storedCredentials = localStorage.getItem("loginCredentials");
    if (storedCredentials) {
      const { password } = JSON.parse(storedCredentials);
      formik.setFieldValue("password", password);
      formik.setFieldValue("confirmpassword", password); 
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmpassword: "",
    },
    onSubmit: async (values) => {
      const forgotData = {
        new_password: values.password, 
      };
      setIsSubmitting(true);
      try{
      const response = await axios.patch(`/resetpassword?reset_token=${token}`, forgotData)
      toast.success("Password changed successfully!", {
      position: toast.POSITION.BOTTOM_CENTER,   
        });
        setTimeout(() => {
          auth.login(response.data);
          navigate("/");
        }, 1500);
      }
        catch(error) {
          const status = error?.response?.status || 500;
          if (status === 400 || status === 401) {
            const errorMessage = "Failed to reset password. Please try again!";
            if (previousToastIdRef.current) {
              toast.dismiss(previousToastIdRef.current);
            }
            previousToastIdRef.current = toast.error(errorMessage, {
              position: toast.POSITION.BOTTOM_CENTER,
            });
          } else if (status === 404) {
            toast.error("Please check the URL or contact support.", {
              position: toast.POSITION.BOTTOM_CENTER,
            });
          }
        }
        finally
         {
          setIsSubmitting(false);
        };
    },
  });
    // validationSchema: Yup.object().shape({
    //   password: Yup.string()
    //     .required("Required")
    //     .max(16, "Password should be less than 16 characters")
    //     .min(6, "Password must be at least 6 characters"),
    //   confirmpassword: Yup.string()
    //     .required("Required")
    //     .oneOf([Yup.ref("password"), null], "Passwords must match") // Ensure passwords match
    //     .max(16, "Password should be less than 16 characters")
    //     .min(6, "Password must be at least 6 characters"),
    // }),
    //vapt fix
    // validationSchema: Yup.object().shape({
    //   password: Yup.string()
    //     .required("Required")
    //     .min(6, "Password must be at least 6 characters")
    //     .max(16, "Password should be less than 16 characters")
    //     .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    //     .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    //     .matches(/[0-9]/, "Password must contain at least one number")
    //     .matches(/[!@#$%^&*(),.?\":{}|<>]/, "Password must contain at least one special character"),
        
    //   confirmpassword: Yup.string()
    //     .required("Required")
    //     .oneOf([Yup.ref("password"), null], "Passwords must match")
    //     .min(6, "Password must be at least 6 characters")
    //     .max(16, "Password should be less than 16 characters"),
    // }),
    
  

  const backgroundStyle = {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  };

  return (
    <div style={backgroundStyle}>
      <div className="left-side-text">
        Game<span style={{ color: "#032254" }}>Mon</span>
        <br />
        <span className="left-side-text2">Smart way to test</span>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <div className="login-container">
          <div>
            <h2 className="heading">Forgot Password</h2>
            <br />
            <form onSubmit={formik.handleSubmit}>
              <label className="label">Password</label>
              <br />
              <div className="password-input-container">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Must be at least 6 Characters"
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  className="input"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="password-toggle-btn"
                >
                  <img
                    src={showPassword ? hide : show}
                    alt={showPassword ? "Hide" : "Show"}
                    style={{ width: "20px", height: "20px" }}
                  />
                </button>
              </div>
              {/* {formik.touched.password && formik.errors.password ? (
                <div className="error">{formik.errors.password}</div>
              ) : null} */}
              <label className="label">Confirm New Password</label>
              <br />
              <div className="password-input-container">
                <input
                  type={showPassword1 ? "text" : "password"}
                  name="confirmpassword"
                  placeholder="Must be at least 6 Characters"
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmpassword}
                  onChange={formik.handleChange}
                  className="input"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword1(!showPassword1)}
                  className="password-toggle-btn"
                >
                  <img
                    src={showPassword1 ? hide : show}
                    alt={showPassword1 ? "Hide" : "Show"}
                    style={{ width: "20px", height: "20px" }}
                  />
                </button>
              </div>
              {/* {formik.touched.confirmpassword && formik.errors.confirmpassword ? (
                <div className="error">{formik.errors.confirmpassword}</div>
              ) : null} */}
              <br />
              <button
                type="submit"
                className="sign-in-button"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Updating..." : "UPDATE"}
              </button>
              <ToastContainer
                toastStyle={{
                  marginTop: "4rem",
                  borderRadius: "10px",
                  backgroundColor: "white",
                  alignContent: "center",
                  closeOnClick: true,
                  textAlign: "center",
                }}
                closeButton={false}
                hideProgressBar={false}
                autoClose={2000}
                position="top-center"
              />
            </form>
            <br />
            <div className="privacy">
              By continuing, you agree to accept our
              <br />
              <span style={{ color: "#83A8FF" }}>Privacy Policy</span> &{" "}
              <span style={{ color: "#83A8FF" }}>Terms of Service</span>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={indlogo} alt="Logo" className="ind-logo" />
          <h1 className="game-mon">GameMon</h1>
        </div>
      </div>
      <div className="copyright">
        Copyright © 2024 Indium Software - All Rights Reserved.
      </div>
    </div>
  );
};

export default ForgetPassword;
