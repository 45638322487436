// import SharingHeader from "./components/Header/Sharing Header";
// import HomeWrapper from "./components/Header/Pages/Home/HomeWrapper";
// import SuperAdminDashboard from "./components/Header/Pages/Home/SuperAdminDashboard";
// import AllSession from "./components/Header/Pages/AllSession/AllSession";
// import Admin from "./components/Header/Pages/Admin/Admin";
// import Projects from "./components/Header/Pages/Projects/Projects";
// import Sharing from "./components/Header/Pages/Home/Sharing";
// import Sessionmain from "./components/Header/Pages/Sessions/Sessions";
// import Profile from "./components/Header/Pages/Profile/Profile";
// import { useContext } from "react";
// import AuthContext from "./hooks/useAuth.js";
// import {
//   BrowserRouter as Router,
//   Routes,
//   Route,
//   Navigate,
// } from "react-router-dom";
// import Login from "./components/Login/Login";
// import ResetPass from "./components/Login/ResetPassword.js";
// import SessionComparison from "./components/Header/Pages/SessionCompare/SessionCompare";
// import Layout from "./components/Header/Pages/Layout";
// import SuperAdmin from "./components/Header/Pages/SuperAdmin/SuperAdmin";
// import Settings from "./components/Header/Pages/Settings/Settings";
// import Adminwrapper from "./components/Header/Pages/Admin/Adminwrapper";
// import SuperAdminwrapper from "./components/Header/Pages/SuperAdmin/SuperAdminwrapper.js";
// import ForgotPassword from "./components/Login/ForgetPassword.js";
// import CreatePassword from "./components/Login/CreatePassword.js"
// function App() {
//   const auth = useContext(AuthContext);
//   const isLoggedIn = auth.isLoggedIn;
//   const role = auth.role;

//   console.log ("logged in user role", auth.role)
//   console.log ("auth data", auth)

//   return (
//     <Router>
//       <Routes>
//         {!isLoggedIn && (
//           <>
//           {/* <Route path="/" element={<SharingHeader />}> */}
//             <Route path="/login" element={<Login />} />
//             {/* <Route path="/login" element={<Sharing />} /> */}
//             {/* </Route> */}
//             <Route path="/" element={<Navigate replace to="/login" />} />
//             <Route exact path="/PasswordReset" element={<ResetPass/>} />
//             <Route exact path="/ForgotPassword/:token" element={<ForgotPassword/>} />
//             <Route exact path="/createPassword/:token" element={<CreatePassword/>} />
//           </>
//         )}

//         {isLoggedIn && (
//           <Route path="/" element={<Layout />}>
//             {/* Default route based on user role */}
//             {role === "admin" && (
//               <Route path="/" element={<Navigate replace to="/Dashboard" />} />
//             )}
//            {role === "admin" && (
//               <Route path="/" element={<Navigate replace to="/Dashboard" />} />
//             )}
//             {role === "super_admin" && (
//               <Route path="/" element={<Navigate replace to="/SuperAdmin" />} />
//             )}

//             {/* Other routes */}
//             <Route exact path="/Dashboard" element={<HomeWrapper />} />
//             <Route exact path="/Admin" element={<Adminwrapper />} />
//             <Route exact path="/SuperAdmin" element={<SuperAdminwrapper />} />
//             <Route exact path="/Sessions" element={<AllSession />} />
//             <Route exact path="/Projects" element={<Projects />} />
//             <Route exact path="/SessionComparison" element={<SessionComparison />} />
//             <Route exact path="/SuperAdmin" element={<SuperAdminDashboard />} />
//             <Route path="/sharing" element={<Sharing />} />
//             {role === "admin" && (
//               <Route path="/Admin/*" element={<Admin />} />
//             )}
            
//             <Route path="/sessions/*" element={<Sessionmain />} />
            
//             {role === "super_admin" && (
//               <Route path="/Invoice/*" element={<SuperAdminDashboard/>} />
//             )}
            
//             <Route exact path="/Settings" element={<Settings />} />
//             <Route exact path="/Profile" element={<Profile />} />
           
//           </Route>
//         )}

//         {/* Shared header for all routes */}
//         <Route path="/" element={<SharingHeader />}>
//           <Route exact path="/shareSessionDetails/*" element={<Sharing />} />
//         </Route>
//       </Routes>
//     </Router>
//   );
// }

// export default App;

import SharingHeader from "./components/Header/Sharing Header";
import HomeWrapper from "./components/Header/Pages/Home/HomeWrapper";
import SuperAdminDashboard from "./components/Header/Pages/Home/SuperAdminDashboard";
import AllSession from "./components/Header/Pages/AllSession/AllSession";
import Admin from "./components/Header/Pages/Admin/Admin";
import Projects from "./components/Header/Pages/Projects/Projects";
import Sharing from "./components/Header/Pages/Home/Sharing";
import Sessionmain from "./components/Header/Pages/Sessions/Sessions";
import Profile from "./components/Header/Pages/Profile/Profile";
import { useContext } from "react";
import AuthContext from "./hooks/useAuth.js";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./components/Login/Login";
import ResetPass from "./components/Login/ResetPassword.js";
import SessionComparison from "./components/Header/Pages/SessionCompare/SessionCompare";
import Layout from "./components/Header/Pages/Layout";
import Settings from "./components/Header/Pages/Settings/Settings";
import Adminwrapper from "./components/Header/Pages/Admin/Adminwrapper";
import SuperAdminwrapper from "./components/Header/Pages/SuperAdmin/SuperAdminwrapper.js";
import ForgotPassword from "./components/Login/ForgetPassword.js";
import CreatePassword from "./components/Login/CreatePassword.js"
import SuperUserAdminWrapper from "./components/Header/Pages/SuperAdmin/user page/SuperUserAdminWrapper.js";
import OrgUsagePage from "./components/Header/Pages/Home/OrgUsagePage.js";
import SessionComparision2 from "./components/Header/Pages/SuperAdmin/session page/SessionComparision2.js";
import HomeWrapperSuperAdmin from "./components/Header/Pages/SuperAdmin/dashboard/HomeWrapperSuperAdmin.js";

function App() {
  const auth = useContext(AuthContext);
  const isLoggedIn = auth.isLoggedIn;
  const role = auth.role;

  return (
    <Router>
      <Routes>
        {!isLoggedIn && (
          <>
          {/* <Route path="/" element={<SharingHeader />}> */}
            <Route path="/login" element={<Login />} />
            {/* <Route path="/login" element={<Sharing />} /> */}
            {/* </Route> */}
            <Route path="/" element={<Navigate replace to="/login" />} />
            <Route exact path="/PasswordReset" element={<ResetPass/>} />
            <Route exact path="/ForgotPassword/:token" element={<ForgotPassword/>} />
            <Route exact path="/createPassword/:token" element={<CreatePassword/>} />
          </>
        )}

        {isLoggedIn && (
          <Route path="/" element={<Layout />}>
            {/* Default route based on user role */}
            {role === "admin" && (
              <Route path="/" element={<Navigate replace to="/Dashboard" />} />
            )}
             {role === "super_admin" && (
              <>
              { <Route path="/" element={<HomeWrapperSuperAdmin/>} /> }
              <Route path="/Invoice/*" element={<SuperAdminDashboard/>} />
             <Route path="/Usage/*" element={<OrgUsagePage/>} />
             <Route path="/UserSuperAdmin" element={<SuperUserAdminWrapper />} /> 
             <Route path="/super_admin" element={<Navigate replace to="/SuperAdmin" />} />
             <Route path="/SessionComparision" element={<SessionComparision2 />} />


             </>
            )}

            {role === "admin" && (
              <>
                        <Route exact path="/Admin" element={<Adminwrapper />} />
</>

            )}
            
            {/* Other routes */}
           
             <Route exact path="/Dashboard" element={<HomeWrapper />} />
            <Route exact path="/SuperAdmin" element={<SuperAdminwrapper />} />
            <Route exact path="/Sessions" element={<AllSession />} />
            {/*<Route exact path="/Projects" element={<Projects />} />*/}
            <Route exact path="/SuperAdmin" element={<SuperAdminDashboard />} />
            <Route exact path="/SessionComparison" element={<SessionComparison />} />

            
            <Route path="/sharing" element={<Sharing />} />
            {role === "admin" && (
              <Route path="/Admin/*" element={<Admin />} />
            )}
            
            <Route path="/sessions/*" element={<Sessionmain />} />
           <Route exact path="/Settings" element={<Settings />} />
            <Route exact path="/Profile" element={<Profile />} />
           
          </Route>
        )}

        {/* Shared header for all routes */}
        <Route path="/" element={<SharingHeader />}>
          <Route exact path="/shareSessionDetails/*" element={<Sharing />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;