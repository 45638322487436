import React, { useState, useEffect, useCallback, useContext, useMemo, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { Grid } from "@material-ui/core";
import FormEditDialog from "../DialogUser";
import FormAddDialog from "../DialogAdd";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import axios from "../../../../../axios/index";
import "./UserSuperAdmin.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Divider, TextField, InputAdornment } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import { ThemePreferenceContext } from "../../../../../index";
import AuthContext from "../../../../../hooks/useAuth";

function UserSuperAdminActive() {
  const [users, SetUsers] = useState([]);
  const [openDel, setOpenDel] = React.useState(false);
  const auth = useContext(AuthContext);
  // const onChanges = (e) => {
  //   const enteredEmail = e.target.value;
  //   setIsValidEmail(enteredEmail.includes('@'));
  //   // ... rest of your onChange logic
  // };
  var randomNum = Math.floor(Math.random() * (99 - 10 + 1)) + 10;
  const { currentTheme, setCurrentTheme } = useContext(ThemePreferenceContext);
  const initialValue = {
    org_id: randomNum,
    name: "",
    email: "",
    password: null,
    phone_number: "1234509876",
    role: "",
    access_end_date: "",
    access_start_date: "",
  };
  const [gridApi, setGridApi] = useState("");
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openAdd, setOpenAdd] = React.useState(false);
  const [updateId, setUpdateId] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  // New states for organization filter
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrgs, setSelectedOrgs] = useState([]);
  const [orgSearchTerm, setOrgSearchTerm] = useState('');
  const [isOrgDropdownOpen, setIsOrgDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);


  const [formData, setFormData] = useState(initialValue);

  // Fetch organizations
  useEffect(() => {
    axios
      .get("/organization", {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setOrganizations(res.data.data);
      });
  }, [auth.token]);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOrgDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Filter organizations
  const filteredOrganizations = organizations
    .filter((org) => org.status_org !== "N")
    .filter((org) =>
      org.org_name.toLowerCase().includes(orgSearchTerm.toLowerCase())
    );

  const toggleOrganization = (org) => {
    setSelectedOrgs(prev =>
      prev.includes(org)
        ? prev.filter(o => o !== org)
        : [...prev, org]
    );
  };

  // Modified user fetching to include organization filter
  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = () => {
      axios.get("/users",   {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      }).then((res) => {
        SetUsers(res.data.data);
      });
    };

  const rowData = users
    ?.filter(data => data?.role !== "super_admin")
    .map(data => ({
      id: data?.id,
      name: data?.name,
      email: data?.email,
      role: data?.role,
      user_since: data?.user_since,
      access_end_date: data?.access_end_date,
      status: data?.status,
      phone_number: data?.phone_number,
      password: data?.password,
      number_of_days_left: data?.number_of_days_left,
      access_start_date: data?.access_start_date,
    }));

  // Filter users based on selected organizations and search term
  const filteredData = useMemo(() => {
    return rowData
      .filter((data) =>
        data.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .filter((data) => {
        if (selectedOrgs.length === 0) return true;
        return selectedOrgs.some(org => org.org_id === data.org_id);
      })
      .filter((data) => data.number_of_days_left > 0)
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [rowData, searchTerm, selectedOrgs]);

  const paginationNumberFormatter = useCallback((params) => {
    // return '[' + params.value.toLocaleString() + ']';
    return params.value;
  }, []);


  const handleClickOpen = () => {
    setOpenAdd(true);
  };
  const handleEditOpen = () => {
    setOpenEdit(true);
  };

  const handleAddClose = () => {
    setOpenAdd(false);
    setFormData(initialValue);
  };
  const handleEditClose = () => {
    setOpenEdit(false);
    setFormData(initialValue);
  };

  const handleClickDelOpen = () => {
    setOpenDel(true);
  };

  const handleDelClose = () => {
    setOpenDel(false);
  };

  useEffect(() => {
    getUsers();
  }, []);

  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);

  const capitalizeFirstLetter = (value) => {
    if (!value) return "";
    return value
      .split(" ")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      cellStyle: { fontSize: "15px", width: 250 },
    };
  }, []);

  const columnDefs = [
    {
      headerName: "Name",
      field: "name",
      flex: 1,
      maxWidth: 700,
      unSortIcon: true,
      valueFormatter: (params) => capitalizeFirstLetter(params.value),
    },
    {
      headerName: "Email",
      field: "email",
      flex: 1.5,
      maxWidth: 250,
      unSortIcon: true,
    },
    {
      headerName: "Role",
      field: "role",
      flex: 1,
      maxWidth: 700,
      unSortIcon: true,
      valueFormatter: (params) => capitalizeFirstLetter(params.value),
    },
    {
      headerName: "Date Joined",
      field: "access_start_date",
      flex: 1,
      maxWidth: 700,
      unSortIcon: true,
    },
    {
      headerName: "Days Left",
      field: "number_of_days_left",
      flex: 1,
      maxWidth: 700,
      unSortIcon: true,
    },
    // { headerName: "PhoneNumber", field: "phone_number" },
    {
      headerName: "Actions",
      field: "id",
      cellRendererFramework: (params) => (
        <div>
          <EditIcon
            variant="outlined"
            color="primary"
            style={{ margin: "10px", cursor: "pointer", color: "#278EF1" }}
            onClick={() => {
              handleUpdate(params.data, params.value);
            }}
          ></EditIcon>
          <DeleteIcon
            variant="outlined"
            color="secondary"
            style={{ margin: "10px", cursor: "pointer", color: "#FF3E63" }}
            onClick={(e) => {
              setUpdateId(params.value);
              handleClickDelOpen();
            }}
          ></DeleteIcon>
        </div>
      ),
    },
  ];



  const onEmail = (e) => {
    const { value, id } = e.target;
    if (value.includes("@") && value.includes(".")) {
      setFormData({ ...formData, [id]: value });
    }
  };

  const onChange = (e) => {
    const { value, id } = e.target;
    setFormData({ ...formData, [id]: value });
  };
  const onRoleChange = (e) => {
    setFormData({ ...formData, role: e.target.value });
  };
  const onDateChange = (e) => {
    setFormData({ ...formData, access_end_date: e.target.value });
  };

  const onGridReady = (params) => {
    setGridApi(params);
  };

  //setting update row data to form data and opening pop up window
  const handleUpdate = (oldData, id) => {
    setUpdateId(id);
    delete oldData.id;
    delete oldData.number_of_days_left;
    delete oldData.status;
    delete oldData.user_since;
    setFormData(oldData);
    handleEditOpen();
  };

  //deleting a user
  const handleDelete = () => {
    handleDelClose();
    axios
      .delete("/user", {
        params: {
          id: updateId,
        },
      })
      .then((res) => {
        toast.error("Deleted Successfully !", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        getUsers();
      });
  };

  const handleFormAddSubmit = () => {
    const formDataWithOrg = { ...formData };
    const values = JSON.stringify(formDataWithOrg);
    axios
      .post("/registerUser", values, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        handleAddClose();
        getUsers();
        toast.success("User Added Successfully !", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      })
      .catch(() => {
        toast.error("Enter all credentails !", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      });
  };

  const handleFormEditSubmit = () => {
    const formDataWithOrg = { ...formData };
    const values = JSON.stringify(formDataWithOrg);
    axios
      .put("/user", values, {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          id: updateId,
        },
      })
      .then((res) => {
        handleEditClose();
        getUsers();
        toast.success("User Edited Successfully !", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      })
      .catch(() => {
        toast.error("Elements are Missing !", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      });
  };

  return (
    <>
      <div style={{ width: "81vw" }}>
        <main>
          <div
            className="ag-theme-alpine"
            style={{
              height: "80vh",
              marginTop: "-3%",
              backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
            }}
          >
            <Grid
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                margin: "0 12px",
                paddingTop: "100px", // Reduced from 40px due to new header
                gap: "20px",
              }}
            >
              {/* Organization Filter Dropdown */}
              <div style={{ position: 'relative' }} ref={dropdownRef}>
                <div
                  onClick={() => setIsOrgDropdownOpen(!isOrgDropdownOpen)}
                  style={{
                    backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                    color: currentTheme === "dark" ? "white" : "black",
                    border: currentTheme === "dark" ? "1px solid #646667" : "1px solid #DFDFDF",
                    borderRadius: "8px",
                    padding: "8px 12px",
                    cursor: "pointer",
                    width: "200px",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}
                >
                  <span style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                  }}>
                    {selectedOrgs.length > 0
                      ? `${selectedOrgs.length} Selected`
                      : "Select Organizations"}
                  </span>
                  <span>▼</span>
                </div>

                {isOrgDropdownOpen && (
                  <div
                    style={{
                      position: "absolute",
                      top: "100%",
                      left: 0,
                      zIndex: 1000,
                      backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                      border: currentTheme === "dark" ? "1px solid #646667" : "1px solid #DFDFDF",
                      borderRadius: "8px",
                      width: "200px",
                      boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
                      marginTop: "4px"
                    }}
                  >
                    <TextField
                      placeholder="Search organizations..."
                      value={orgSearchTerm}
                      onChange={(e) => setOrgSearchTerm(e.target.value)}
                      style={{ margin: "8px", width: "calc(100% - 16px)" }}
                      size="small"
                      variant="outlined"
                    />
                    <div style={{
                      maxHeight: "200px",
                      overflowY: "auto",
                      padding: "8px"
                    }}>
                      {filteredOrganizations.map((org) => (
                        <div
                          key={org.org_id}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "8px",
                            cursor: "pointer",
                            backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                            color: currentTheme === "dark" ? "white" : "black",
                          }}
                          onClick={() => toggleOrganization(org)}
                        >
                          <input
                            type="checkbox"
                            checked={selectedOrgs.includes(org)}
                            onChange={() => { }}
                            style={{ marginRight: "8px" }}
                          />
                          <span>{org.org_name}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              {/* User Search Field */}
              <TextField
                variant="outlined"
                type="text"
                placeholder="Search user"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon
                        style={{
                          color: currentTheme === "dark" ? "#99999F" : "#818E94",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  height: "40px",
                  flex: 1,
                  "& input::placeholder": {
                    color: currentTheme === "dark" ? "#99999F" : "#818E94",
                    opacity: 1,
                  },
                  "& .MuiInputBase-root": {
                    height: "40px !important",
                    backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                    borderRadius: "8px",
                    color: currentTheme === "dark" ? "#99999F" : "#000000",
                  },
                }}
              />

              {/* Create User Button
              <List onClick={handleClickOpen}>
                <div
                  style={{
                    color: "#FFFFFF",
                    background: "#4779EF",
                    borderRadius: "7px",
                    width: "145px",
                    height: "40px",
                  }}
                >
                  <ListItem button>
                    <div style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%"
                    }}>
                      <ListItemText
                        primary={
                          <Typography style={{ color: "white" }}>
                            Create User
                          </Typography>
                        }
                      />
                      <AddIcon />
                    </div>
                  </ListItem>
                </div>
              </List> */}
            </Grid>

            {/* AG Grid Component */}
            <div
              style={{
                height: "75%",
                width: "100%",
                marginTop: "20px",
                boxShadow: "0px 25px 30px #0000000A",
                border: currentTheme === "dark" ? "1px solid #646667" : "1px solid #DFDFDF",
              }}
              className={currentTheme === "dark" ? "ag-theme-alpine-dark" : "ag-theme-alpine"}
            >
              {/* <div style={gridStyle}> */}
              <AgGridReact
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                rowData={filteredData}
                pagination={true}
                paginationPageSize={8}
                paginationNumberFormatter={paginationNumberFormatter}
              />
              {/* </div> */}
            </div>
          </div>

          <FormEditDialog
            open={openEdit}
            handleClose={handleEditClose}
            data={formData}
            onChange={onChange}
            onEmail={onEmail}
            onRoleChange={onRoleChange}
            onDateChange={onDateChange}
            updateId={updateId}
            handleFormSubmit={handleFormEditSubmit}
          />
          <FormAddDialog
            open={openAdd}
            handleClose={handleAddClose}
            data={formData}
            onChange={onChange}
            onRoleChange={onRoleChange}
            onDateChange={onDateChange}
            onEmail={onEmail}
            updateId={updateId}
            handleFormSubmit={handleFormAddSubmit}
          />

          <Dialog
            PaperProps={{
              sx: {
                width: "425px",
                height: "225px",
                backgroundColor:
                  currentTheme === "dark" ? "#1F2327" : "#FFFFFF",
              },
            }}
            open={openDel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <DialogTitle
                style={{
                  fontSize: "20px",
                  fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                  color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
                }}
              // id="alert-dialog-title"
              >
                Delete User
              </DialogTitle>
              <CancelIcon
                onClick={handleDelClose}
                style={{
                  marginTop: "4.3%",
                  cursor: "pointer",
                  marginRight: "2.5vh",
                  color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
                }}
              />
            </div>
            <Divider
              style={{
                margin: "0vw 1.5vw",
                width: "80%",
                backgroundColor: "grey",
              }}
            />
            <DialogContent>
              <h4 style={{ color: "grey" }}>
                Are you sure, you want to delete the user ?
              </h4>
            </DialogContent>
            <DialogActions
              style={{
                marginLeft: "1vw",
                display: "flex",
                justifyContent: "row",
                marginRight: "5vw",
                marginTop: "125px",
                // marginBottom:'1vh',
                position: "fixed",
                gap: "20px",
              }}
            >
              <List>
                <div
                  style={{
                    color: "#FFFFFF",
                    marginTop: "8%",
                    background: "#4779EF",
                    borderRadius: "8px",
                    width: "160px",
                    height: "40px",
                    padding: "3px",
                    fontSize: "15px",
                  }}
                >
                  <ListItem
                    button
                    onClick={handleDelete}
                    style={{
                      paddingLeft: "30%",
                      // marginTop: "4%",
                      fontFamily: "normal normal bold 16px/21px Product Sans",
                    }}
                    color="secondary"
                    variant="outlined"
                  >
                    Proceed
                  </ListItem>
                </div>
              </List>
              <List onClick={handleDelClose}>
                <div
                  style={{
                    color: "#4779EF",
                    marginTop: "8%",
                    border: "2px solid #4779EF",
                    boxShadow: "white",
                    borderRadius: "8px",
                    width: "160px",
                    height: "40px",
                    padding: "7px",
                    fontSize: "15px",
                  }}
                >
                  <ListItem
                    button
                    onClick={handleDelClose}
                    style={{
                      paddingLeft: "30%",
                      marginTop: "-4%",
                      fontFamily: "normal normal bold 16px/21px Product Sans",
                    }}
                    color="secondary"
                    variant="outlined"
                  >
                    Cancel
                  </ListItem>
                </div>
              </List>
            </DialogActions>
          </Dialog>
          <ToastContainer
            toastStyle={{
              marginTop: "4rem",
              borderRadius: "10px",
              backgroundColor: "white",
              alignContent: "center",
              height: 50,
              margin: 10,
              width: 300,
              closeOnClick: true,
              textAlign: "center",
            }}
            closeButton={false}
            hideProgressBar={false}
            autoClose={3000}
            position="top-center"
            className="toast-container"
            toastClassName="dark-toast"
          />
        </main>
      </div>
    </>
  );
}

export default UserSuperAdminActive;