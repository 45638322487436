import React, { useContext } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { TextField } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Divider, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { ThemePreferenceContext} from "../../../../index";
import "./DialogUser.css";
import AuthContext from "../../../../hooks/useAuth";
import axios from "../../../../axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function FormEditDialog({
  open,
  handleClose,
  data,
  onChange,
  handleFormSubmit,
  onRoleChange,
  onEmail,
  onDateChange,
  formData,
}) {
  const { name, email, password, phone_number, role, access_end_date,org_id } = data;
  const { currentTheme, setCurrentTheme } = useContext(ThemePreferenceContext);

  return (
    <div>
      <Dialog
        PaperProps={{
          style: {
            boxShadow: "none",
            // maxWidth: "xl",
            width: "27vw",
            height: "460px",
            backgroundColor: currentTheme === "dark" ? "#1F2327" : "#FFFFFF",
          },
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
        >
          <DialogTitle
            style={{
              fontSize: "50px",
              fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
              color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
            }}
            // id="alert-dialog-title"
          >
            Edit User
          </DialogTitle>
          <CancelIcon
            onClick={handleClose}
            style={{
              marginTop: "4.3%",
              cursor: "pointer",
              marginRight: "2.5vh",
              color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
            }}
          />
        </div>
        <Divider
          style={{
            margin: "0vw 1.5vw",
            width: "22.5vw",
            backgroundColor: "grey",
          }}
        />
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}
        >
          <form>
            <Typography style={{ color: "#818E94" }}>Name</Typography>
            <TextField
              id="name"
              value={name}
              onChange={(e) => onChange(e)}
              InputProps={{
                placeholder: "Enter Name",
                inputProps: {
                  style: {
                    color: currentTheme === "dark" ? "#818E94" : "black",textTransform:"capitalize"
                  },
                },
              }}
              // label="Name"
              variant="outlined"
              style={{
                borderRadius: "5px",
                width: "22.5vw",
                backgroundColor:
                  currentTheme === "dark" ? "#292E33" : "#FFFFFF",
              }}
              margin="dense"
            />
            <Typography style={{ color: "#818E94" }}>Email</Typography>
            <TextField
              id="email"
              value={email}
              type="email"
              onChange={onEmail}
              InputProps={{
                placeholder: "Enter name",
                style: {color: currentTheme === "dark" ? "#818E94" : "black", },
              }}
              // label="Email"
              variant="outlined"
              style={{
                borderRadius: "5px",
                width: "22.5vw",
                backgroundColor:
                  currentTheme === "dark" ? "#292E33" : "#FFFFFF",
              }}
              margin="dense"
            />

            {/* <TextField
              id="password"
              value={password}
              onChange={(e) => onChange(e)}
              placeholder="Enter password"
              label="password"
              variant="outlined"
              style={{ marginLeft: "2vh" }}
              margin="dense"
            /> */}

            {/* <TextField
              id="phone_number"
              value={phone_number}
              onChange={(e) => {
                if (e.target.value.length <= 10) {
                  onChange(e);
                }
              }}
              placeholder="Enter phone_number "
              label="phone_number"
              style={{ marginLeft: "2vh" }}
              variant="outlined"
              margin="dense"
            /> */}
            <Typography style={{ color: "#818E94" }}>Role</Typography>
            <FormControl
              style={{
                borderRadius: "5px",
                width: "22.5vw",
                marginTop: "2%",
                backgroundColor:
                  currentTheme === "dark" ? "#292E33" : "#FFFFFF",
              }}
            >
              {/* <InputLabel id="demo-simple-select-label"  style={{color:"#818E94"}} >Role</InputLabel> */}
              <Select
                name="role"
                value={role}
                // label="role"
                // InputLabelProps={{
                //   style: { color: "#818E94"}
                // }}
                style={{height: "40px",color: currentTheme === "dark" ? "#818E94" : "black",}}
                onChange={onRoleChange}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxWidth: "200px",
                      color: currentTheme === "dark" ? "#818E94" : "black",
                      background: currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                    },
                  },
                }}
              >
                <MenuItem value="admin">Admin</MenuItem>
                <MenuItem value="tester">Tester</MenuItem>
                {/* <MenuItem value="super_admin">Super Admin</MenuItem> */}
              </Select>
            </FormControl>
            <Typography style={{ color: "#818E94",marginTop:"1%" }}>Access End Date</Typography>
            <TextField
            // style={{
            //   borderRadius: "5px",
            //   width: "22.5vw",
            //   marginTop: "2%",
            //   backgroundColor:
            //     currentTheme === "dark" ? "#292E33" : "#FFFFFF",
            // }}
              id="access_end_date"
              type="date"
              // label="End Date"
              value={access_end_date}
              onChange={onDateChange}
              variant="outlined"
              style={{ width: "22.5vw", marginTop: "2%" ,backgroundColor: currentTheme === "dark" ? "#292E33" : "#FFFFFF",}}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                style: {
                  padding: 8.5,
                  color: currentTheme === "dark" ? "#818E94" : "black",
                },
              }}
              margin="dense"
            />
          </form>
        </DialogContent>
        <DialogActions
          style={{
            // marginLeft: "5%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <List onClick={() => handleFormSubmit()}>
            <div
              style={{
                color: "#FFFFFF",
                // marginTop: "8%",
                background: "#4779EF",
                borderRadius: "10px",
                width: "22.5vw",
                height: "40px",
                padding: "3px",
                fontSize: "15px",
              }}
            >
              <ListItem
                button
                onClick={handleClose}
                style={{
                  paddingLeft: "9.2vw",
                  fontFamily: "normal normal bold 16px/21px Product Sans",
                }}
                color="secondary"
                variant="outlined"
              >
                Save
              </ListItem>
            </div>
          </List>
          {/* 
          <List onClick={handleClose}>
            <div
              style={{
                color: "#278EF1",
                marginTop: "8%",
                border: "2px solid #278EF1",
                boxShadow: "white",
                borderRadius: "25px",
                width: "120px",
                height: "31px",
                padding: "3px",
                fontSize: "15px",
              }}
            >
              <ListItem
                button
                onClick={handleClose}
                style={{
                  paddingLeft: "33%",
                  fontFamily: "normal normal bold 16px/21px Product Sans",
                }}
                color="secondary"
                variant="outlined"
              >
                Cancel
              </ListItem>
            </div>
          </List> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}
