import React, { useMemo, useState, useEffect, useContext, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import axios from "../../../../../axios/index";
import AuthContext from "../../../../../hooks/useAuth";
import "./SessionCompare.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { ThemePreferenceContext } from "../../../../../index";
import Plotly from "plotly.js-basic-dist";
import createPlotlyComponent from "react-plotly.js/factory";
import Divider from "@material-ui/core/Divider/Divider";
import { FormControl, InputAdornment, } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import ListItem from "@material-ui/core/ListItem";
import AndroidIcon from "@mui/icons-material/Android";
import AppleIcon from "@mui/icons-material/Apple";
import ListItemText from "@material-ui/core/ListItemText";
import { Typography } from "@mui/material";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import TuneIcon from "@mui/icons-material/Tune";
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import BookOutlinedIcon from "@mui/icons-material/BookOutlined";
import BookIcon from "@mui/icons-material/Book";
import MenuItem from "@mui/material/MenuItem";
import { debounce } from "lodash";
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';

const Plot = createPlotlyComponent(Plotly);
function SessionComparision2() {
  const [FirstUserdata, SetFirstUserdata] = React.useState([]);

  const [allSession, setAllSession] = useState([]);
  const [selectedSession, setSelectedSession] = useState([]);
  const currentUserId = JSON.parse(
    sessionStorage.getItem("currentSessionData")
  );
  const { currentTheme, setCurrentTheme } = useContext(ThemePreferenceContext);
  var [arr, setArr] = useState([]);
  const auth = useContext(AuthContext);
  const userId = auth.id || currentUserId;
  const compData = auth.compData;
  const [selectedOption, setSelectedOption] = useState("");
  const [searchText, setSearchText] = useState("");
  const [showClearIcon, setShowClearIcon] = useState("none");
  const [filteredList, setFilteredList] = useState([]);
  const [AllSessionsActive, setAllSessionsActive] = useState(false);
  const [Allsessions, setAllsessions] = useState("");
  const [selecteditem, setSelecteditem] = React.useState([]);
  const [filterTab, setFilterTab] = useState(false);
  const [pageScroll, setPageScroll] = useState(1);
  const [appNameList, setAppNameList] = useState([]);
  const [sessionList, setSessionList] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [appName, setAppName] = useState([]);
  const [sessionName, setSessionName] = useState([]);
  const [deviceName, setDeviceName] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [bookmarkedSessions, setBookmarkedSessions] = useState({});
  const handleSelectOption = (event) => {
    setSelectedOption(event.target.value);
  };
  const [sessionNoteDrop, setSessionNoteDrop] = useState(false);
  const [UserActive, setUserActive] = useState(false);
  const role = auth.role;

  //Organization drop-down states
  const [OrgActive, setOrgActive] = useState(false);
  const [Organization, setOrganization] = useState(null);
  const [FirstOrgData, setFirstOrgData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [orgInfo, setOrgInfo] = useState(null);
  const [error, setError] = useState(null);
  const OrgmenuRef = useRef(null);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState([]);



  const handleOrgSelect = (org) => {
    setSelectedOrganization(org);  // Set the selected organization
    setOrgActive(false);  // Close the dropdown after selection
  };

  //organization functions
  useEffect(() => {
    getOrganizations();
  }, []);

  const getOrganizations = () => {
    setLoading(true);
    axios
      .get("/organization", {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        // Ensure we only store valid organization data
        if (res.data && Array.isArray(res.data.data)) {
          const validOrgs = res.data.data.filter(
            org => org && org.org_name && org.status_org === 'Y'
          );
          setFirstOrgData(validOrgs);
          setError(null);
        } else {
          setFirstOrgData([]);
          setError("No valid organization data found");
        }
      })

      .catch((error) => {
        console.error("Error fetching organizations:", error);
        setError("Failed to fetch organizations");
        setFirstOrgData([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getOrgInfo = (orgId) => {
    setLoading(true);
    axios
      .get(`/organization/${orgId}`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data && res.data.data) {
          setOrgInfo(res.data.data);
          setError(null);
        } else {
          setError("No organization details found");
        }
      })
      .catch((error) => {
        console.error("Error fetching organization information:", error);
        setError("Failed to fetch organization details");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const singleOrgItem = (e, data, i) => {
    if (data && data.org_id) {  // Use org_id as the unique identifier
      getOrgInfo(data.org_id); // Fetch organization info using org_id
    } else {
      console.error("Invalid organization data:", data);
      setError("Invalid organization selected");
    }
  };
  useEffect(() => {
    let isMount = true;
    axios
      .get("/users", {
        headers: { Authorization: `Bearer ${auth.token}` },
      })
      .then((res) => {
        console.log("API Response:", res.data);
        if (isMount) {
          SetFirstUserdata(Array.isArray(res.data.data) ? res.data.data : []);
        }
      })
      .catch((err) => console.error("Error fetching users:", err));

    return () => { isMount = false; };
  }, []);

  useEffect(() => {
    if (Array.isArray(FirstUserdata)) {
      if (selectedOrganization) {
        const usersInOrganization = FirstUserdata.filter(
          (user) => user.organizationId === selectedOrganization.id
        );
        setFilteredUsers(usersInOrganization);
      } else {
        setFilteredUsers(FirstUserdata);
      }
    } else {
      setFilteredUsers([]);
    }
  }, [selectedOrganization, FirstUserdata]);



  // Safe sort functions
  const safeSort = (array) => {
    if (!Array.isArray(array)) return [];
    return [...array].sort((a, b) => {
      if (!a?.org_name || !b?.org_name) return 0;  // Sort by org_name instead of name
      return a.org_name.localeCompare(b.org_name);
    });
  };


  const closeDropdown = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setSessionNoteDrop(false);
      setUserActive(false);
      setOrgActive(false);

    }
  };

  //Users drop down states and functions

  const [User, setUser] = useState("");
  const UsermenuRef = useRef(null);
  const [selectedUseritem, setSelectedUseritem] = useState("");
  const [calls, setcall] = useState(auth.id || "");

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get("/users", {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        });

        let filteredUsers = response.data;

        // Filter users by selected organization if one is selected
        if (selectedOrganization) {
          filteredUsers = response.data.filter(
            user => user.organizationId === selectedOrganization.org_id
          );
        }

        SetFirstUserdata(filteredUsers);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, [selectedOrganization, auth.token]);

  const singleUserItem = (e, data, id) => {
    setSelectedUseritem(id);
    axios.get("/users", {
      headers: {
        Authorization: `Bearer ${auth.token}`,
      },
    });
  };

  const handleUserSelect = (e, data, i) => {
    setUser(data.name);
    auth.userId(data.id);
    setUserActive(false);
    setcall(data.id);
    setAllSession([]);
    setPageScroll(1);
    singleUserItem(e, data, i);
  };

  function Users(e, data, i) {
    const day = data.id;
    setcall(data.id);
    setAllSession([]);
    setPageScroll(1);
  }


  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      cellStyle: { fontSize: "15px", width: 250 },
    };
  }, []);
  const [selectedTraceData, setselectedTraceData] = useState([]);
  const options = [
    "CPU Usage",
    "GPU Usage",
    "Avg Memory",
    "FPS",
    "Downloaded Data",
    "Uploaded Data",
    // "AppPowerData",
  ];
  useEffect(() => {
    if (selectedOption === "GPU Usage") {
      setselectedTraceData(gpu);
    } else if (selectedOption === "CPU Usage") {
      setselectedTraceData(cpu);
    } else if (selectedOption === "Avg Memory") {
      setselectedTraceData(avgmemory);
    } else if (selectedOption === "FPS") {
      setselectedTraceData(fps);
    } else if (selectedOption === "Downloaded Data") {
      setselectedTraceData(downloadData);
    } else if (selectedOption === "Uploaded Data") {
      setselectedTraceData(upload_data);
    } else if (selectedOption === "AppPowerData") {
      setselectedTraceData(apppowerData);
    } else {
      setselectedTraceData(empty);
    }
  }, [selectedOption]);
  const session = compData?.map((item) => item.sessionname);
  const cpu_usage = compData?.map((item) => item.cpu_app_usage);
  const maxcpuvalues = cpu_usage?.map((subArray) =>
    Math?.max(...(subArray ?? []))
  );
  const mincpuvalues = cpu_usage?.map((subArray) =>
    Math?.min(...(subArray ?? []))
  );
  const cpu_time = compData?.map((item) => item.cpu_usage_time);
  const gpu_usage = compData?.map((item) => item.avg_gpu_usage);
  const maxgpuvalues = gpu_usage?.map((subArray) =>
    Math?.max(...(subArray ?? []))
  );
  const mingpuvalues = gpu_usage?.map((subArray) =>
    Math?.min(...(subArray ?? []))
  );
  const gpu_time = compData?.map((item) => item.gpu_usage_time);
  const memory_time = compData?.map((item) => item.memory_usage_time);
  const memory_usage = compData?.map((item) => item.avg_memory_usage);
  const maxmemoryvalues = memory_usage?.map((subArray) =>
    Math?.max(...(subArray ?? []))
  );
  const minmemoryvalues = memory_usage?.map((subArray) =>
    Math?.min(...(subArray ?? []))
  );
  const upload_usage = compData?.map((item) => item.uploaddata_app_usage);
  const maxuploadvalues = upload_usage?.map((subArray) =>
    Math?.max(...(subArray ?? []))
  );
  const minuploadvalues = upload_usage?.map((subArray) =>
    Math?.min(...(subArray ?? []))
  );
  const upload_time = compData?.map((item) => item.upload_data_usage_time);
  const downloadData_usage = compData?.map(
    (item) => item.downloadddata_app_uage
  );
  const maxddvalues = downloadData_usage?.map((subArray) =>
    Math?.max(...(subArray ?? []))
  );
  const minddvalues = downloadData_usage?.map((subArray) =>
    Math?.min(...(subArray ?? []))
  );
  const downloadData_time = compData?.map(
    (item) => item.download_data_usage_time
  );
  const fps_usage = compData?.map((item) => item.averagefps_app_usage);
  const fps_time = compData?.map((item) => item.average_fps_app_usage_time);
  const apppower_time = compData?.map((item) => item.stablityfps_app_time);
  const apppower_usage = compData?.map((item) => item.stablityfps_app_useage);
  const superuser = [
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ];
  const maxapppowervalues = apppower_usage?.map((subArray) =>
    Math?.max(...(subArray ?? []))
  );
  const minapppowervalues = apppower_usage?.map((subArray) =>
    Math?.min(...(subArray ?? []))
  );

  const empty = [];
  for (let i = 0; i < gpu_time?.length; i++) {
    const repeatedData = Array.from(
      { length: gpu_time[i]?.length },
      (_, index) => index
    );
    const max = Math.max(repeatedData.length);
    const trace1 = {
      x: repeatedData,
      y: superuser,
      name: session[i],
      hovertemplate: "<b>GPU : %{y}(%)<br>Time : %{x}(secs)</br></b>",
      type: "scatter",
      line: { shape: "spline" },
      marker: { size: "3" },
    };
    const colors = [
      "#8300DB",
      "#0B46D3",
      "#4779EF",
      "#FF3A29",
      "#FF9100",
      "#FEC400",
      "#35DB41",
      "#01880B",
    ];
    if (i < colors.length) {
      trace1.marker.color = colors[i];
    }
    empty.push(trace1);
  }

  const gpu = [];
  for (let i = 0; i < gpu_time?.length; i++) {
    const repeatedData = Array.from(
      { length: gpu_time[i]?.length },
      (_, index) => index
    );
    const max = Math.max(repeatedData.length);
    const trace1 = {
      x: repeatedData,
      y: gpu_usage[i],
      name: session[i],
      hovertemplate: "<b>GPU : %{y}(%)<br>Time : %{x}(secs)</br></b>",
      type: "scatter",
      line: { shape: "spline" },
      marker: { size: "3" },
    };
    const colors = [
      "#8300DB",
      "#0B46D3",
      "#4779EF",
      "#FF3A29",
      "#FF9100",
      "#FEC400",
      "#35DB41",
      "#01880B",
    ];
    if (i < colors.length) {
      trace1.marker.color = colors[i];
    }
    gpu.push(trace1);
  }
  const cpu = [];
  for (let i = 0; i < cpu_time?.length; i++) {
    const repeatedData = Array.from(
      { length: cpu_time[i]?.length },
      (_, index) => index
    );
    const trace2 = {
      x: repeatedData,
      y: cpu_usage[i],
      name: session[i],
      hovertemplate: "<b>CPU : %{y}(%)<br>Time : %{x}(secs)</br></b>",
      type: "scatter",
      line: { shape: "spline" },
      marker: { size: "3" },
    };
    const colors = [
      "#8300DB",
      "#0B46D3",
      "#4779EF",
      "#FF3A29",
      "#FF9100",
      "#FEC400",
      "#35DB41",
      "#01880B",
    ];
    if (i < colors.length) {
      trace2.marker.color = ("hi", colors[i]);
    }
    cpu.push(trace2);
  }

  const avgmemory = [];
  for (let i = 0; i < memory_time?.length; i++) {
    const repeatedData = Array.from(
      { length: gpu_time[i]?.length },
      (_, index) => index
    );
    const trace2 = {
      x: repeatedData,
      y: memory_usage[i],
      name: session[i],
      hovertemplate: "<b>Avg Memory : %{y}(KiB)<br>Time : %{x}(secs)</br></b>",
      type: "scatter",
      line: { shape: "spline" },
      marker: { size: "3" },
    };
    const colors = [
      "#8300DB",
      "#0B46D3",
      "#4779EF",
      "#FF3A29",
      "#FF9100",
      "#FEC400",
      "#35DB41",
      "#01880B",
    ];
    if (i < colors.length) {
      trace2.marker.color = colors[i];
    }
    avgmemory.push(trace2);
  }

  const upload_data = [];
  for (let i = 0; i < upload_time?.length; i++) {
    const repeatedData = Array.from(
      { length: gpu_time[i]?.length },
      (_, index) => index
    );
    const trace2 = {
      x: repeatedData,
      y: upload_usage[i],
      name: session[i],
      hovertemplate: "<b>Upload Data : %{y}(KiB)<br>Time : %{x}(secs)</br></b>",
      type: "scatter",
      line: { shape: "spline" },
      marker: { size: "3" },
    };
    const colors = [
      "#8300DB",
      "#0B46D3",
      "#4779EF",
      "#FF3A29",
      "#FF9100",
      "#FEC400",
      "#35DB41",
      "#01880B",
    ];
    if (i < colors.length) {
      trace2.marker.color = colors[i];
    }
    upload_data.push(trace2);
  }

  const fps = [];
  for (let i = 0; i < fps_time?.length; i++) {
    const repeatedData = Array.from(
      { length: gpu_time[i]?.length },
      (_, index) => index
    );
    const trace2 = {
      x: repeatedData,
      y: fps_usage[i],
      name: session[i],
      hovertemplate: "<b>FPS : %{y}<br>Time : %{x}(secs)</br></b>",
      type: "scatter",
      line: { shape: "spline" },
      marker: { size: "3" },
    };
    const colors = [
      "#8300DB",
      "#0B46D3",
      "#4779EF",
      "#FF3A29",
      "#FF9100",
      "#FEC400",
      "#35DB41",
      "#01880B",
    ];
    if (i < colors.length) {
      trace2.marker.color = colors[i];
    }
    fps.push(trace2);
  }

  const downloadData = [];
  for (let i = 0; i < downloadData_time?.length; i++) {
    const repeatedData = Array.from(
      { length: gpu_time[i]?.length },
      (_, index) => index
    );
    const trace = {
      x: repeatedData,
      y: downloadData_usage[i],
      name: session[i],
      hovertemplate: "<b>Download Data : %{y}(KiB)<br>Time : %{x}(secs)</br></b>",
      type: "scatter",
      line: { shape: "spline" },
      marker: { size: "3" },
    };
    const colors = [
      "#8300DB",
      "#0B46D3",
      "#4779EF",
      "#FF3A29",
      "#FF9100",
      "#FEC400",
      "#35DB41",
      "#01880B",
    ];
    if (i < colors.length) {
      trace.marker.color = colors[i];
    }
    downloadData.push(trace);
  }

  const apppowerData = [];
  for (let i = 0; i < apppower_time?.length; i++) {
    const repeatedData = Array.from(
      { length: gpu_time[i]?.length },
      (_, index) => index
    );
    const trace = {
      x: repeatedData,
      y: apppower_usage[i],
      name: session[i],
      hovertemplate: "<b>App Power : %{y}<br>Time : %{x}(secs)</br></b>",
      type: "scatter",
      line: { shape: "spline" },
      marker: { size: "3" },
    };
    const colors = [
      "#8300DB",
      "#0B46D3",
      "#4779EF",
      "#FF3A29",
      "#FF9100",
      "#FEC400",
      "#35DB41",
      "#01880B",
    ];
    if (i < colors.length) {
      trace.marker.color = colors[i];
    }
    apppowerData.push(trace);
  }

  function Sessions(pageNum) {
    axios
      .get("/mySessions", {
        params: {
          userId: userId,
          page: pageNum,
          size: 50,
        },
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then((res) => {
        if (res?.data?.data?.length) {
          setAllSession((prevRes) => [...prevRes, ...res.data.data]);
          // Initialize or update bookmarkedSessions based on the new data
          setBookmarkedSessions((prevBookmarks) => {
            const newBookmarks = { ...prevBookmarks };
            res.data.data.forEach((session) => {
              newBookmarks[session.session_id] = session.is_bookmarked === "true";
            });
            return newBookmarks;
          });
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          console.log(error); // Empty array when API returns 404 status code
        }
      });
  }

  useEffect(() => {
    Sessions(pageScroll);
  }, []);

  const onSelectionChanged = (e, index, sessionId) => {
    if (selecteditem.length <= 8) {
      setSelecteditem((prevSelectedItems) => {
        if (prevSelectedItems.includes(index)) {
          return prevSelectedItems.filter((item) => item !== index);
        } else {
          return [...prevSelectedItems, index];
        }
      });
    } else {
      setSelecteditem((prevSelectedItems) => {
        return prevSelectedItems.filter((item) => item !== index);
      });
    }

    if (selecteditem.includes(index)) {
      let list = selectedSession.filter(
        (item) => item.session_id !== sessionId
      );
      setSelectedSession(list);
    } else {
      setSelectedSession((prevSelectedSession) => [...prevSelectedSession, e]);
    }
  };

  useEffect(() => {
    setArr([]);
    for (let i = 0; i < selectedSession?.length; i++) {
      setArr((ps) => [...ps, selectedSession[i].session_id]);
    }
  }, [selectedSession]);

  const compareSession = () => {
    if (selecteditem?.length <= 1) {
      if (!toastVisible.current) {
        toast.dismiss();
        toastVisible.current = true;
        toast.error("you have to select at least 2 sessions to compare!", {
          position: toast.POSITION.BOTTOM_CENTER,
          onClose: () => {
            toastVisible.current = false;
          },
        });
      }
    } else if (selecteditem?.length > 8) {
      if (!toastVisible.current) {
        toast.dismiss();
        toastVisible.current = true;
        toast.error("You can only select up to 8 sessions!", {
          position: toast.POSITION.BOTTOM_CENTER,
          onClose: () => {
            toastVisible.current = false;
          },
        });
      }
    } else {
      axios
        .get("/compareSessions", {
          params: {
            userId: userId,
            s1: arr[0] ? arr[0] : "",
            s2: arr[1] ? arr[1] : "",
            s3: arr[2] ? arr[2] : "",
            s4: arr[3] ? arr[3] : "",
            s5: arr[4] ? arr[4] : "",
            s6: arr[5] ? arr[5] : "",
            s7: arr[6] ? arr[6] : "",
            s8: arr[7] ? arr[7] : "",
          },
          headers: { Authorization: `Bearer ${auth.token}` },
        })
        .then((res) => {
          auth.compDataHandler(res.data.data);
          // navigate("/SessionComparison/details");
        });
    }
  };

  const toastVisible = useRef(false);
  useEffect(() => {
    if (selecteditem.length && arr.length) {
      compareSession();
    }
  }, [selecteditem, arr]);

  React.useEffect(() => {
    const sessionData = window.sessionStorage.getItem("sessiondata");
    const savedValues = JSON.parse(sessionData);
    setAllSession(savedValues?.allSession);
    // setSelectedSession(savedValues?.selectedSession);
  }, []);

  React.useEffect(() => {
    const valuesToSave = {
      allSession,
      selectedSession,
    };
    window.sessionStorage.setItem("sessiondata", JSON.stringify(valuesToSave));
  });

  const capitalizeFirstLetter = (value) => {
    if (!value) return "";
    return value.charAt(0).toUpperCase() + value.slice(1);
  };

  const rowDatas = compData?.map((session, index) => {
    return {
      app_name: session?.app_name,
      sessionname: session?.sessionname,
      average_fps_value: session?.average_fps_value,
      cpu_average_usage: session?.cpu_average_usage,
      maxcpuvalue: maxcpuvalues[index],
      mincpuvalue: mincpuvalues[index],
      device_name: session?.device_name,
      fps_stability: isNaN(session?.fps_stability) ? 0 : session?.fps_stability,
      gpu_average_usage: session?.gpu_average_usage,
      maxgpuvalue: maxgpuvalues[index],
      mingpuvalue: mingpuvalues[index],
      memory_average_usage: session?.memory_average_usage,
      maxmemoryvalues: maxmemoryvalues[index],
      minmemoryvalues: minmemoryvalues[index],
      peak_memory_value: session?.peak_memory_value,
      upload_data_usage_average: session?.upload_data_usage_average,
      maxuploadvalues: maxuploadvalues[index],
      minuploadvalues: minuploadvalues[index],
      created_at: session?.created_at,
      download_data_usage_average: session?.download_data_usage_average,
      maxddvalues: maxddvalues[index],
      minddvalues: minddvalues[index],
      cpu_app_usage: session?.cpu_app_usage,
      maxapppowervalues: maxapppowervalues[index],
      minapppowervalues: minapppowervalues[index],
    };
  });

  const columnDefss = [
    {
      field: "app_name",
      headerName: "Application",
      unSortIcon: true,
      width: 280,
      cellClass: "center-align",
      cellStyle: {
        fontSize: "14px",
        fontWeight: "bold",
        color: currentTheme === "dark" ? "#FFFFFF" : "#262626",
      },
      headerClass: currentTheme === "dark" ? "header-dark" : "header-light",
    },
    {
      field: "device_name",
      headerName: "Device",
      unSortIcon: true,
      cellClass: "center-align",
      cellStyle: {
        fontSize: "14px",
        fontWeight: "bold",
        color: currentTheme === "dark" ? "#FFFFFF" : "#262626",
      },
      headerClass: currentTheme === "dark" ? "header-dark" : "header-light",
      valueFormatter: (params) => capitalizeFirstLetter(params.value),
    },
    {
      field: "sessionname",
      headerName: "Session Name",
      unSortIcon: true,
      cellClass: "center-align",
      cellStyle: {
        fontSize: "14px",
        fontWeight: "bold",
        color: currentTheme === "dark" ? "#FFFFFF" : "#262626",
      },
      headerClass: currentTheme === "dark" ? "header-dark" : "header-light",
      valueFormatter: (params) => capitalizeFirstLetter(params.value),
    },
    {
      field: "average_fps_value",
      headerName: "FPS",
      unSortIcon: true,
      cellStyle: {
        fontSize: "14px",
        fontWeight: "bold",
        color: currentTheme === "dark" ? "#FFFFFF" : "#262626",
      },
      headerClass: currentTheme === "dark" ? "header-dark" : "header-light",
    },
    {
      field: "fps_stability",
      headerName: "FPS Stability",
      unSortIcon: true,
      cellStyle: {
        fontSize: "14px",
        fontWeight: "bold",
        color: currentTheme === "dark" ? "#FFFFFF" : "#262626",
      },
      headerClass: currentTheme === "dark" ? "header-dark" : "header-light",
    },
    {
      field: "cpu_average_usage",
      headerName: "CPU",
      unSortIcon: true,
      cellClass: "space-reduce",
      cellStyle: {
        fontSize: "14px",
        fontWeight: "bold",
        color: currentTheme === "dark" ? "#FFFFFF" : "#262626",
      },
      headerClass: currentTheme === "dark" ? "header-dark" : "header-light",
      cellRenderer: (params) => {
        const cpuUsage = params.data.cpu_average_usage;
        const maxCpuValue = params.data.maxcpuvalue;
        const minCpuValue = params.data.mincpuvalue;
        return `${cpuUsage} <br />Min: ${minCpuValue} - Max: ${maxCpuValue}`;
      },
    },
    {
      field: "gpu_average_usage",
      headerName: "GPU",
      unSortIcon: true,
      cellClass: "space-reduce",
      cellStyle: {
        fontSize: "14px",
        fontWeight: "bold",
        color: currentTheme === "dark" ? "#FFFFFF" : "#262626",
      },
      headerClass: currentTheme === "dark" ? "header-dark" : "header-light",
      cellRenderer: (params) => {
        const gpuUsage = params.data.gpu_average_usage;
        const maxgpuValue = params.data.maxgpuvalue;
        const mingpuValue = params.data.mingpuvalue;
        return `${gpuUsage} <br />Min: ${mingpuValue} - Max: ${maxgpuValue}`;
      },
    },
    {
      field: "memory_average_usage",
      headerName: "Memory",
      unSortIcon: true,
      cellClass: "space-reduce",
      cellStyle: {
        fontSize: "14px",
        fontWeight: "bold",
        color: currentTheme === "dark" ? "#FFFFFF" : "#262626",
      },
      headerClass: currentTheme === "dark" ? "header-dark" : "header-light",
      cellRenderer: (params) => {
        const memory_average_usage = params.data.download_data_usage_average;
        const maxmemoryvalues = params.data.maxmemoryvalues;
        const minmemoryvalues = params.data.minmemoryvalues;
        return `${memory_average_usage} <br />Min: ${minmemoryvalues} - Max: ${maxmemoryvalues}`;
      },
    },
    // { field: "peak_memory_value", headerName: "Peak Memory", unSortIcon: true },
    {
      field: "download_data_usage_average",
      headerName: "Download Data",
      unSortIcon: true,
      cellClass: "space-reduce",
      cellStyle: {
        fontSize: "14px",
        fontWeight: "bold",
        color: currentTheme === "dark" ? "#FFFFFF" : "#262626",
      },
      headerClass: currentTheme === "dark" ? "header-dark" : "header-light",
      cellRenderer: (params) => {
        const download_data_usage_average =
          params.data.download_data_usage_average;
        const maxddvalues = params.data.maxddvalues;
        const minddvalues = params.data.minddvalues;
        return `${download_data_usage_average} <br />Min: ${minddvalues} - Max: ${maxddvalues}`;
      },
    },
    {
      field: "upload_data_usage_average",
      headerName: "Upload Data",
      unSortIcon: true,
      cellClass: "space-reduce",
      cellStyle: {
        fontSize: "14px",
        fontWeight: "bold",
        color: currentTheme === "dark" ? "#FFFFFF" : "#262626",
      },
      headerClass: currentTheme === "dark" ? "header-dark" : "header-light",
      cellRenderer: (params) => {
        const upload_data_usage_average = params.data.upload_data_usage_average;
        const maxuploadvalues = params.data.maxuploadvalues;
        const minuploadvalues = params.data.minuploadvalues;
        return `${upload_data_usage_average} <br />Min: ${minuploadvalues} - Max: ${maxuploadvalues}`;
      },
    },
    // { field: "created_at", headerName: "Date", unSortIcon: true },
  ];

  React.useEffect(() => {
    const sessionData = window.sessionStorage.getItem("sessiondata");
    const savedValues = JSON.parse(sessionData);
    setAllSession(savedValues?.allSession);
    setSelectedOption(savedValues?.selectedOption);
  }, []);

  React.useEffect(() => {
    const valuesToSave = {
      allSession,
      selectedOption,
    };
    window.sessionStorage.setItem("sessiondata", JSON.stringify(valuesToSave));
  });

  useEffect(() => {
    const sortedSessions = allSession?.sort(
      (a, b) => new Date(b.session_date) - new Date(a.session_date)
    );
    setFilteredList(sortedSessions);
  }, [allSession]);

  const handleChangeSearch = (event) => {
    let val = event.target.value;
    setShowClearIcon(val === "" ? "none" : "flex");
    setSearchText(val);

    const filteredItems = allSession.filter(
      (list) =>
        list.app_name.toLowerCase().includes(val.toLowerCase()) ||
        list.sessionname.toLowerCase().includes(val.toLowerCase())
    );

    setFilteredList(filteredItems);
  };

  const handleChangeApp = (event) => {
    const {
      target: { value },
    } = event;

    setAppName(typeof value === "string" ? value.split(",") : value);
  };

  const handleChangeSession = (event) => {
    const {
      target: { value },
    } = event;

    setSessionName(typeof value === "string" ? value.split(",") : value);
  };

  const handleChangeDevice = (event) => {
    const {
      target: { value },
    } = event;

    setDeviceName(typeof value === "string" ? value.split(",") : value);
  };

  const handleCloseSelect = () => {
    setIsOpen(false);
  };

  const handleOkButtonClick = () => {
    setIsOpen(false);
  };

  const handleClickRemoveText = () => {
    setSearchText("");
    setShowClearIcon("none");

    const sortedSessions = allSession?.sort(
      (a, b) => new Date(b.session_date) - new Date(a.session_date)
    );
    setFilteredList(sortedSessions);
  };

  const handleFilterTab = () => {
    setFilterTab(!filterTab);
  };

  const handleCloseFilter = () => {
    setFilterTab(false);
  };

  const handleApplyFilter = () => {
    const uniqueSessionNames = new Set();
    const filteredItems = [];
    if (
      appName.length === 0 &&
      sessionName.length === 0 &&
      deviceName.length === 0
    ) {
      toast.error("Choose at least 1 to Apply Filter!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }
    allSession.forEach((item) => {
      const matchesAppname =
        appName.length === 0 || appName.includes(item.app_name);
      const matchesSessionName =
        sessionName.length === 0 || sessionName.includes(item.sessionname);
      const matchesDeviceName =
        deviceName.length === 0 || deviceName.includes(item.device_name);

      if (matchesAppname && matchesSessionName && matchesDeviceName) {
        if (!uniqueSessionNames.has(item.sessionname)) {
          uniqueSessionNames.add(item.sessionname);
          filteredItems.push(item);
        }
      }
    });

    setFilteredList(filteredItems);
    setFilterTab(false);
  };

  const handleClearFilters = () => {
    setDeviceName([]);
    setAppName([]);
    setSessionName([]);
    setFilteredList(allSession);
    setFilterTab(false);
  };

  const handleAddBookmark = (e, sessionId) => {
    e.stopPropagation();
    setBookmarkedSessions((prev) => ({
      ...prev,
      [sessionId]: true,
    }));
    const values = JSON.stringify({
      session_id: sessionId,
      user_id: userId,
    });
    axios
      .post("/bookmark", values, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.status) {
          toast.dismiss();
          toast.success("Bookmark Added!", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteBookmark = (e, sessionId) => {
    e.stopPropagation();
    setBookmarkedSessions((prev) => ({
      ...prev,
      [sessionId]: false,
    }));
    axios
      .delete("/removeBookmark", {
        data: {
          session_id: sessionId,
          user_id: userId,
        },
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then((res) => {
        if (res.status) {
          toast.dismiss();
          toast.error("Bookmark Removed!", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteSession = (sessionId) => {
    setAllSession([]);
    axios
      .delete("/deleteSession", {
        params: {
          sessionId: sessionId,
        },
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then((res) => {
        if (res.status) {
          Sessions(pageScroll);
        }
        {
          const errorMessage = "Session Deledted Successfully !";
          toast.success(errorMessage, {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          toast.error("Deletion Failed !!!", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
      });
  };

  const handleScroll = (e) => {
    const bottom =
      Number((e.target.scrollHeight - e.target.scrollTop).toFixed(0)) -
      e.target.clientHeight <
      50;
    if (bottom) {
      setPageScroll((prevRes) => prevRes + 1);
    }
  };

  const debouncedHandleScroll = debounce(handleScroll, 400);

  useEffect(() => {
    if (pageScroll > 1) {
      Sessions(pageScroll);
    }
  }, [pageScroll]);

  function getAllFilterList() {
    const distinctValuesApp = new Set();
    const distinctValuesSession = new Set();
    const distinctValuesDevice = new Set();
    let appList = [];
    let sessionList = [];
    let deviceList = [];

    for (const obj of allSession) {
      const valueApp = obj["app_name"];
      const valueSession = obj["sessionname"];
      const valueDevice = obj["device_name"];

      if (!distinctValuesApp.has(valueApp)) {
        distinctValuesApp.add(valueApp);
        appList.push(valueApp);
      }
      if (!distinctValuesSession.has(valueSession)) {
        distinctValuesSession.add(valueSession);
        sessionList.push(valueSession);
      }
      if (!distinctValuesDevice.has(valueDevice)) {
        distinctValuesDevice.add(valueDevice);
        deviceList.push(valueDevice);
      }
    }

    setAppNameList(appList);
    setSessionList(sessionList);
    setDeviceList(deviceList);
  }

  useEffect(() => {
    if (allSession?.length) {
      getAllFilterList();
    }
  }, [allSession]);

  const isNotEnoughSessionsSelected = selecteditem.length < 2;

  function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }
  const dropdownRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredAppNames, setFilteredAppNames] = useState([]);
  const [filteredSessionNames, setFilteredSessionNames] = useState([]);
  // other states mentioned previously: allSession, appNameList, sessionList, appName

  useEffect(() => {
    // Effect to filter and set filtered app names based on selected device
    if (deviceName.length > 0) {
      const filteredApps = allSession
        .filter(item => deviceName.includes(item.device_name))
        .map(item => item.app_name);
      const uniqueApps = [...new Set(filteredApps)];
      setFilteredAppNames(uniqueApps);
    } else {
      setFilteredAppNames([...appNameList]);
    }
  }, [deviceName, allSession, appNameList]);

  useEffect(() => {
    // Effect to filter and set filtered session names based on selected device and app
    if (deviceName.length > 0 && appName.length > 0) {
      const filteredSessions = allSession
        .filter(item => deviceName.includes(item.device_name) && appName.includes(item.app_name))
        .map(item => item.sessionname);
      const uniqueSessions = [...new Set(filteredSessions)];
      setFilteredSessionNames(uniqueSessions);
    } else {
      setFilteredSessionNames([...sessionList]);
    }
  }, [deviceName, appName, allSession, sessionList]);

  const [isOpenDevice, setIsOpenDevice] = useState(null);
  const [isOpenApp, setIsOpenApp] = useState(null);
  const [isOpenSession, setIsOpenSession] = useState(null);

  const toggleDropdown = (dropdownType) => {
    if (dropdownType === "device") {
      setIsOpenDevice(isOpenDevice === null ? dropdownType : null);
      setIsOpenApp(null); // Close other dropdowns
      setIsOpenSession(null);
    } else if (dropdownType === "app") {
      setIsOpenApp(isOpenApp === null ? dropdownType : null);
      setIsOpenDevice(null); // Close other dropdowns
      setIsOpenSession(null);
    } else if (dropdownType === "session") {
      setIsOpenSession(isOpenSession === null ? dropdownType : null);
      setIsOpenDevice(null); // Close other dropdowns
      setIsOpenApp(null);
    }
  };

  const toggleDevice = (device) => {
    // Function to toggle selected devices
    const updatedDevices = deviceName.includes(device)
      ? deviceName.filter((d) => d !== device)
      : [...deviceName, device];
    setDeviceName(updatedDevices);
  };
  const toggleAppName = (appname) => {
    const updatedApps = appName.includes(appname)
      ? appName.filter((item) => item !== appname)
      : [...appName, appname];
    setAppName(updatedApps);
  };
  const toggleSessionName = (sessionname) => {
    const updatedSessions = sessionName.includes(sessionname)
      ? sessionName.filter((item) => item !== sessionname)
      : [...sessionName, sessionname];
    setSessionName(updatedSessions);
  };
  const handleClickOutside = (event) => {
    // Function to handle click outside dropdown to close it
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    // Effect to add and remove event listener for outside click detection
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            minWidth: "235px",
            width: "235px",
            borderRadius: "5px",
            marginLeft: "1vw",
            marginTop: "30px"
          }}
        >
          {/* Organization dropdown */}
          {role === "super_admin" && (
            <div className="dropdown-containerss" ref={OrgmenuRef}>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  closeDropdown(OrgActive);
                  setOrgActive(!OrgActive);
                }}
                className="dropdown-buttonss"
                style={{
                  backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                  color: currentTheme === "dark" ? "white" : "black",
                  textTransform: "capitalize",
                }}
                ref={dropdownRef}
              >
                <div>
                  {selectedOrganization ? selectedOrganization.org_name : "Organization"}
                  <span className="down-arrowss">&#9660;</span>
                </div>
              </div>

              <div
                className="dropdown-contentss"
                style={{
                  display: OrgActive ? "block" : "none",
                  backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                }}
              >
                <div
                  className="scrollable-listss"
                  style={{
                    backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                    color: currentTheme === "dark" ? "white" : "black",
                    textTransform: "capitalize",
                  }}
                >
                  {loading && <div className="p-2">Loading organizations...</div>}
                  {error && <div className="p-2 text-red-500">{error}</div>}
                  {!loading && !error && FirstOrgData.length === 0 && (
                    <div className="p-2">No organizations found</div>
                  )}
                  {!loading &&
                    !error &&
                    FirstOrgData.length > 0 &&
                    safeSort(FirstOrgData).map((data, i) => (
                      <div
                        onClick={() => handleOrgSelect(data)}
                        style={{
                          backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                          color: currentTheme === "dark" ? "white" : "black",
                          padding: "8px 12px",
                          cursor: "pointer",
                        }}
                        key={i}
                      >
                        {data?.org_name || "Unnamed Organization"}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}
        </div>

        <div
          style={{
            minWidth: "235px",
            width: "235px",
            borderRadius: "5px",
            marginLeft: "1vw",
            marginTop: "30px"
          }}
        >
          {/* User Details Dropdown */}
          {role === "super_admin" && (
            <div className="dropdown-containerss" ref={UsermenuRef}>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  closeDropdown(UserActive);
                  setUserActive(!UserActive);
                }}
                className="dropdown-buttonss"
                style={{
                  backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                  color: currentTheme === "dark" ? "white" : "black",
                  textTransform: "capitalize",
                }}
                ref={dropdownRef}
              >
                <div>
                  {User ? User : "User"}
                  <span className="down-arrowss">&#9660;</span>
                </div>
              </div>

              <div
                className="dropdown-contentss"
                style={{
                  display: UserActive ? "block" : "none",
                  backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                }}
              >
                <div
                  className="scrollable-listss"
                  style={{
                    backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                    color: currentTheme === "dark" ? "white" : "black",
                    textTransform: "capitalize",
                  }}
                >
                  {Array.isArray(FirstUserdata) &&
                    FirstUserdata.slice()
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((data, i) => (
                        <div
                          onClick={(e) => {
                            setUser(e.target.textContent);
                            auth.userId(data.id);
                            setUserActive(!UserActive);
                            singleUserItem(e, data, i);
                            Users(e, data, i);
                          }}
                          style={{
                            backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                            color: currentTheme === "dark" ? "white" : "black",
                          }}
                          key={i}
                        >
                          {data.name}
                        </div>
                      ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>


      <div
        style={{
          marginTop: "30px",
          padding: "16px",
        }}
      >
        {/* left */}
        <div
          style={{
            display: "flex",
            float: "left",
            position: "fixed",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <FormControl>
              <TextField
                size="small"
                variant="outlined"
                value={searchText}
                placeholder={"Search"}
                onChange={handleChangeSearch}
                InputProps={{
                  style: {
                    background: currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                    border:
                      currentTheme === "dark"
                        ? "1px solid #646667"
                        : "1px solid #DFDFDF",
                    color: currentTheme === "dark" ? "#FFFFFF" : "#292E33",
                    width: "21.5vw",
                    borderRadius: "5px",
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      style={{ display: showClearIcon, cursor: "pointer" }}
                      onClick={handleClickRemoveText}
                    >
                      <ClearIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "7px",
                background: currentTheme === "dark" ? "#363C43" : "#FFFFFF",
                boxShadow: "0px 2px 6px #29489803",
                borderRadius: "5px",
                marginLeft: "0.5%"
              }}
              onClick={handleFilterTab}
            >
              <TuneIcon
                sx={{
                  fontSize: 30,
                  color: currentTheme === "dark" ? "#FFFFFF" : "",
                  cursor: "pointer",
                }}
                titleAccess="Filters"
                className="tuneIcon"
              />
            </div>
            {/* <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "5px",
              background: currentTheme === "dark" ? "#363C43" : "#FFFFFF",
              boxShadow: "0px 2px 6px #29489803",
              borderRadius: "5px",
              marginLeft:"1%"
            }}
            onClick={handleFilterTab}
          >
            <SellOutlinedIcon
              sx={{
                fontSize: 30,
                color: currentTheme === "dark" ? "#FFFFFF" : "",
                cursor: "pointer",
              }}
              titleAccess="Search by Tag"
            />
          </div> */}
          </div>
          <div
            value={currentTheme}
            onChange={(e) => {
              setCurrentTheme(e.target.value);
            }}
            style={{
              display: AllSessionsActive != 404 ? "block" : "none",
              marginTop: "1.5%",
              cursor: "pointer",
              width: "25vw",
              backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
              borderRadius: "10px",
              height: "78vh",
              overflowY: "auto",
              overflowX: "hidden",
              scrollBehavior: "smooth",
              boxShadow: "0px 2px 6px #0000000A",
            }}
            className="ScrollBars"
            onScroll={(e) => debouncedHandleScroll(e)}
          >
            {/* List of sessions */}
            {!filterTab ? (
              filteredList?.map((data, i) => (
                <div
                  key={i}
                  onClick={(e) => {
                    setAllsessions(e.target.textContent);
                    setAllSessionsActive(!AllSessionsActive);
                    onSelectionChanged(data, i, data.session_id);
                  }}
                  style={{
                    background: selecteditem.includes(i)
                      ? "transparent linear-gradient(180deg, #6398F5 0%, #4475EE 100%) 0% 0% no-repeat padding-box"
                      : currentTheme === "dark"
                        ? "#292E33"
                        : "white",
                    color: selecteditem.includes(i)
                      ? "white"
                      : currentTheme === "dark"
                        ? "white"
                        : "black",
                    fontSize: "20px",
                    borderRadius: "8px",
                    marginLeft: "5px",
                    marginBottom: "-4px",
                    width: "97%",
                  }}
                >

                  {/* Session Information */}
                  <ListItem>
                    {data.device_name === "apple" ? (
                      <AppleIcon sx={{ fontSize: 40, marginLeft: "-15px" }} />
                    ) : (
                      <AndroidIcon sx={{ fontSize: 40, marginLeft: "-15px" }} />
                    )}
                    <ListItemText>
                      <div
                        // variant="body"
                        style={{
                          fontWeight: "bold",
                          marginLeft: "15px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          color: selecteditem.includes(i)
                            ? "white"
                            : currentTheme === "dark"
                              ? "white"
                              : "#4779EF",
                        }}
                        title={data?.app_name}
                        className="sess-appname"
                      >
                        {data?.app_name}
                      </div>
                      <Typography
                        variant="body1"
                        style={{
                          textTransform: "capitalize",
                          marginLeft: "15px",
                          color: selecteditem.includes(i)
                            ? "white"
                            : currentTheme === "dark"
                              ? "#C5CACF"
                              : "#494949",
                        }}
                        id="scroll-fonts"
                      >
                        {data.device_name}
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{
                          marginLeft: "15px",
                          color: selecteditem.includes(i)
                            ? "white"
                            : currentTheme === "dark"
                              ? "#C5CACF"
                              : "#494949",
                        }}
                        id="scroll-fonts"
                      >
                        Time Played{" "}
                        <span
                          style={{
                            color: selecteditem.includes(i)
                              ? "white"
                              : currentTheme === "dark"
                                ? "#C5CACF"
                                : "#494949",
                            fontWeight: "600",
                          }}
                          id="scroll-fonts"
                        >
                          {data?.session_duration !== undefined && (
                            <span>
                              {typeof data.session_duration === 'number' ? (
                                <>{formatTime(data.session_duration)}</>
                              ) : (
                                <>
                                  {data.session_duration && typeof data.session_duration === 'string' && data.session_duration.includes(':') ? (
                                    data.session_duration
                                  ) : (
                                    formatTime(data.session_duration)
                                  )}
                                </>
                              )}
                            </span>
                          )}

                        </span>
                      </Typography>
                    </ListItemText>
                    <div
                      style={{
                        marginBottom: "2vh",
                        display: "flex",
                        gap: "10%",
                      }}
                    >
                      {selecteditem.includes(i) ? (
                        <DeleteOutlineOutlinedIcon
                          className="scroll-icon"
                          titleAccess="Delete"
                          onClick={() => handleDeleteSession(data.session_id)}
                        />
                      ) : (
                        ""
                      )}
                      {bookmarkedSessions[data.session_id] ? (
                        <BookIcon
                          className="scroll-icon"
                          titleAccess="Remove Bookmark"
                          onClick={(e) => handleDeleteBookmark(e, data.session_id)}
                        />
                      ) : (
                        <BookOutlinedIcon
                          className="scroll-icon"
                          titleAccess="Add Bookmark"
                          onClick={(e) => handleAddBookmark(e, data.session_id)}
                        />
                      )}
                    </div>
                  </ListItem>
                  <hr
                    style={{
                      border: "none",
                      borderBottom:
                        currentTheme === "dark"
                          ? "0.5px solid #A2B1C2"
                          : "1px solid #e6e6e6",
                      margin: "5px 0",
                    }}
                  />
                </div>
              )) // Filter tab
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "5%",
                }}
              >
                <div
                  style={{
                    fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                                'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                                sans-serif`,
                    color: currentTheme === "dark" ? "#A2B1C2" : "#494949",
                    fontSize: "14px",
                    fontWeight: "600",
                    cursor: "text"
                  }}
                >
                  Filters:
                  <span
                    onClick={handleClearFilters}
                    style={{ float: "right", cursor: "pointer", color: deviceName.length > 0 || sessionName.length > 0 || appName.length > 0 ? "#4779EF" : "transparent" }}
                  >
                    Clear Filter
                  </span>
                </div>
                <FormControl sx={{ m: 5, width: 600 }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "4%",
                      justifyContent: "space-around",
                    }}
                  >
                    <div style={{ paddingTop: "9%" }}>
                      <InputLabel
                        id="device-label"
                        style={{
                          color: currentTheme === "dark" ? "#FFFFFF" : "#818E94",
                          fontSize: "14px",
                        }}
                      >
                        Device
                      </InputLabel>
                      <div ref={dropdownRef} style={{ position: "relative" }}>
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleDropdown("device");
                          }}
                          className="dropdown-button"
                          style={{
                            backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                            color: currentTheme === "dark" ? "#818E94" : "black",
                            textTransform: "capitalize",
                            width: "22vw",
                            height: "6vh",
                            marginTop: "1%",
                            fontSize: "14px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "0 10px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <div style={{ flex: 1, overflow: "hidden", textOverflow: "ellipsis" }}>
                            {deviceName.length > 0 ? deviceName.join(", ") : ""}
                          </div>
                          <span className="down-arrow">&#x2BC6;</span>
                        </div>


                        {isOpenDevice === "device" && (
                          <div
                            className="dropdown-content"
                            style={{
                              display: "block",
                              backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                              color: currentTheme === "dark" ? "#818E94" : "black",
                              width: "22vw",
                              maxHeight: "200px",
                              overflowY: "auto",
                              position: "absolute",
                              zIndex: 1,
                              marginTop: "1px",
                              fontSize: "14px",
                            }}
                          >
                            <input
                              type="text"
                              placeholder="Search..."
                              value={searchTerm}
                              onClick={(e) => e.stopPropagation()}
                              onChange={(e) => setSearchTerm(e.target.value)}
                              style={{
                                width: "19.5vw",
                                margin: "10px",
                                marginBottom: "-5px",
                                padding: "5px",
                                borderRadius: "5px",
                                textTransform: "capitalize",
                                fontSize: "14px",
                                border:
                                  currentTheme === "dark"
                                    ? "1px solid #646667"
                                    : "1px solid #DFDFDF",
                                outline: "none",
                                backgroundColor:
                                  currentTheme === "dark" ? "#292E33" : "white",
                                color: currentTheme === "dark" ? "white" : "black"
                              }}
                            />
                            <div
                              style={{
                                backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                                color: currentTheme === "dark" ? "#818E94" : "black",
                                marginLeft: "1%",
                                padding: "10px",
                              }}
                            >
                              {deviceList
                                .filter((device) => device.toLowerCase().includes(searchTerm.toLowerCase()))
                                .sort((a, b) => a.localeCompare(b))
                                .map((name) => (
                                  <div
                                    key={name}
                                    onClick={() => {
                                      toggleDevice(name);
                                      setIsOpenDevice(null);
                                      setSearchTerm("");
                                    }}
                                    style={{
                                      backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                                      color: currentTheme === "dark" ? "#818E94" : "black",
                                      textTransform: "capitalize",
                                      padding: "5px",
                                      cursor: "pointer",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      checked={deviceName.includes(name)}
                                      onChange={() => toggleDevice(name)}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                      style={{ marginRight: "8px" }}
                                    />
                                    {name}
                                  </div>
                                ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div style={{ paddingTop: "9%" }}>
                      <InputLabel
                        id="app-name-label"
                        style={{
                          color: currentTheme === "dark" ? "#FFFFFF" : "#818E94",
                          fontSize: "14px",
                        }}
                      >
                        App Name
                      </InputLabel>
                      <div ref={dropdownRef} style={{ position: "relative" }}>
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleDropdown("app");
                          }}
                          className="dropdown-button"
                          style={{
                            backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                            color: currentTheme === "dark" ? "#818E94" : "black",
                            width: "22vw",
                            height: "6vh",
                            marginTop: "1%",
                            fontSize: "14px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "0 10px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <div style={{ flex: 1, overflow: "hidden", textOverflow: "ellipsis" }}>{appName.length > 0 ? appName.join(", ") : ""}</div>
                          <span className="down-arrow">&#x2BC6;</span>
                        </div>

                        {isOpenApp === "app" && (
                          <div
                            className="dropdown-content"
                            style={{
                              display: "block",
                              backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                              color: currentTheme === "dark" ? "#818E94" : "black",
                              width: "22vw",
                              maxHeight: "200px",
                              overflowY: "auto",
                              position: "absolute",
                              zIndex: 1,
                              marginTop: "1px",
                              fontSize: "14px",
                            }}
                          >
                            <input
                              type="text"
                              placeholder="Search..."
                              value={searchTerm}
                              onClick={(e) => e.stopPropagation()}
                              onChange={(e) => setSearchTerm(e.target.value)}
                              style={{
                                width: "19.5vw",
                                margin: "10px",
                                marginBottom: "-5px",
                                padding: "5px",
                                borderRadius: "5px",
                                textTransform: "capitalize",
                                fontSize: "14px",
                                border:
                                  currentTheme === "dark"
                                    ? "1px solid #646667"
                                    : "1px solid #DFDFDF",
                                outline: "none",
                                backgroundColor:
                                  currentTheme === "dark" ? "#292E33" : "white",
                                color: currentTheme === "dark" ? "white" : "black"
                              }}
                            />
                            <div
                              style={{
                                backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                                color: currentTheme === "dark" ? "#818E94" : "black",
                                marginLeft: "1%",
                                padding: "10px",
                              }}
                            >
                              {filteredAppNames
                                .filter((appname) => appname.toLowerCase().includes(searchTerm.toLowerCase()))
                                .sort((a, b) => a.localeCompare(b))
                                .map((name) => (
                                  <div
                                    key={name}
                                    onClick={() => {
                                      toggleAppName(name);
                                      setIsOpenApp(null);
                                      setSearchTerm("");
                                    }}
                                    style={{
                                      backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                                      color: currentTheme === "dark" ? "#818E94" : "black",
                                      padding: "5px",
                                      cursor: "pointer",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      checked={appName.includes(name)}
                                      onChange={() => toggleAppName(name)}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                      style={{ marginRight: "8px" }}
                                    />
                                    {name}
                                  </div>
                                ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div style={{ paddingTop: "9%" }}>
                      <InputLabel
                        id="session-label"
                        style={{
                          color: currentTheme === "dark" ? "#FFFFFF" : "#818E94",
                          fontSize: "14px",
                        }}
                      >
                        Session
                      </InputLabel>
                      <div ref={dropdownRef} style={{ position: "relative" }}>
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleDropdown("session");
                          }}
                          className="dropdown-button"
                          style={{
                            backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                            color: currentTheme === "dark" ? "#818E94" : "black",
                            width: "22vw",
                            height: "6vh",
                            marginTop: "1%",
                            fontSize: "14px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "0 10px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <div style={{ flex: 1, overflow: "hidden", textOverflow: "ellipsis" }}>{sessionName.length > 0 ? sessionName.join(", ") : ""}</div>
                          <span className="down-arrow">&#x2BC6;</span>
                        </div>

                        {isOpenSession === "session" && (
                          <div
                            className="dropdown-content"
                            style={{
                              display: "block",
                              backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                              color: currentTheme === "dark" ? "#818E94" : "black",
                              width: "22vw",
                              maxHeight: "200px",
                              overflowY: "auto",
                              position: "absolute",
                              zIndex: 1,
                              marginTop: "1px",
                              fontSize: "14px",
                            }}
                          >
                            <input
                              type="text"
                              placeholder="Search..."
                              value={searchTerm}
                              onClick={(e) => e.stopPropagation()}
                              onChange={(e) => setSearchTerm(e.target.value)}
                              style={{
                                width: "19.5vw",
                                margin: "10px",
                                marginBottom: "-5px",
                                padding: "5px",
                                borderRadius: "5px",
                                textTransform: "capitalize",
                                fontSize: "14px",
                                border:
                                  currentTheme === "dark"
                                    ? "1px solid #646667"
                                    : "1px solid #DFDFDF",
                                outline: "none",
                                backgroundColor:
                                  currentTheme === "dark" ? "#292E33" : "white",
                                color: currentTheme === "dark" ? "white" : "black",
                              }}
                            />
                            <div
                              style={{
                                backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                                color: currentTheme === "dark" ? "#818E94" : "black",
                                marginLeft: "1%",
                                padding: "10px",
                              }}
                            >
                              {filteredSessionNames
                                .filter((session) => session.toLowerCase().includes(searchTerm.toLowerCase()))
                                .sort((a, b) => a.localeCompare(b))
                                .map((name) => (
                                  <div
                                    key={name}
                                    onClick={() => {
                                      toggleSessionName(name);
                                      setIsOpenSession(null);
                                      setSearchTerm("");
                                    }}
                                    style={{
                                      backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                                      color: currentTheme === "dark" ? "#818E94" : "black",
                                      padding: "5px",
                                      cursor: "pointer",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      checked={sessionName.includes(name)}
                                      onChange={() => toggleSessionName(name)}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                      style={{ marginRight: "8px" }}
                                    />
                                    {name}
                                  </div>
                                ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "15px",
                      marginTop: "19vh",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      style={{
                        width: "180px",
                        height: "48px",
                        borderRadius: "40px",
                        marginTop: "-10vh",
                        background:
                          "transparent linear-gradient(180deg, #659AF5 0%, #4374EE 100%) 0% 0% no-repeat padding-box",
                        fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                    sans-serif`,
                      }}
                      onClick={handleApplyFilter}
                    >
                      Apply Filters
                    </Button>
                    <Button
                      variant="outlined"
                      style={{
                        width: "180px",
                        height: "48px",
                        borderRadius: "40px",
                        marginTop: "-10vh",
                        color: currentTheme === "dark" ? "#FFFFFF" : "#494949",
                        fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                    sans-serif`,
                      }}
                      onClick={handleCloseFilter}
                    >
                      Cancel
                    </Button>
                  </div>
                </FormControl>
              </div>
            )}
          </div>
        </div>

        {/* right */}
        <div style={{ marginLeft: "10%" }}>
          <h5
            style={{
              marginTop: "-1%",
              marginLeft: "19.5vw",
              color: currentTheme === "dark" ? "white" : "black",
            }}
            className="title"
          >
            Sessions Comparison
          </h5>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div>
              <div
                style={{
                  display: "flex",
                  borderRadius: "10px",
                  padding: "10px",
                  marginLeft: "19vw",
                }}
              >
                <div style={{ width: "50vw", height: "auto" }}>
                  <div style={{ height: "200px", boxSizing: "border-box" }}>
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        marginTop: "0px",
                        boxShadow: "0px 25px 30px #0000000A",
                        border:
                          currentTheme === "dark"
                            ? "1px solid #646667"
                            : "1px solid #DFDFDF",
                      }}
                      className={
                        currentTheme === "dark"
                          ? "ag-theme-alpine-dark"
                          : "ag-theme-alpine"
                      }
                    >
                      <AgGridReact
                        rowData={isNotEnoughSessionsSelected ? [] : rowDatas}
                        columnDefs={columnDefss}
                        rowSelection="multiple"
                        defaultColDef={defaultColDef}
                        rowMultiSelectWithClick={true}
                        rowHeight={50}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div
                style={{
                  width: "50vw",
                  backgroundColor:
                    currentTheme === "dark" ? "#292E33" : "white",
                  borderRadius: "10px",
                  marginLeft: "19.5vw",
                }}
              >
                <div style={{ display: "flex" }}>
                  <h5
                    style={{
                      float: "left",
                      marginLeft: "2%",
                      marginTop: "1.5%",
                      color: currentTheme === "dark" ? "white" : "black",
                    }}
                    className="title"
                  >
                    Sessions Comparison Chart
                  </h5>
                  <select
                    style={{
                      marginTop: "1%",
                      float: "right",
                      width: "200px",
                      height: "30px",
                      backgroundColor:
                        currentTheme === "dark" ? "#363C43" : "#F2F2F2",
                      borderRadius: "5px",
                      color: currentTheme === "dark" ? "white" : "black",
                      fontSize: "14px",
                      fontWeight: "400",
                      cursor: "pointer",
                      marginBottom: "5px",
                    }}
                    className="select-metrics"
                    value={selectedOption}
                    onChange={handleSelectOption}
                  >
                    <option value="">Select Metrics</option>
                    {options.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
                <Divider style={{ width: "100%" }} />
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <Plot
                      data={
                        isNotEnoughSessionsSelected ? [] : selectedTraceData
                      }
                      layout={{
                        X: 0,
                        xanchor: "left",
                        height: 260,
                        width: 590,
                        margin: { l: 70, r: 40, b: 95, t: 40, pad: 15 },
                        xref: 450,
                        text: selectedOption,
                        font: {
                          color:
                            currentTheme === "dark" ? "#FFFFFF" : "#000000",
                        },
                        borderRadius: 20,
                        showTips: true,

                        xaxis: {
                          gridcolor: "gray",
                          zeroline: false,
                          line: {
                            color:
                              currentTheme === "dark" ? "#FFFFFF" : "#000000",
                          },
                          tickfont: {
                            color:
                              currentTheme === "dark" ? "#FFFFFF" : "#000000",
                          },
                          autorange: true,
                          linecolor:
                            currentTheme === "dark" ? "#FFFFFF" : "#000000",
                          showgrid: true,
                          title: {
                            text: "Time (secs)",
                            font: {
                              family:
                                '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
                              size: 14,
                              color:
                                currentTheme === "dark" ? "#FFFFFF" : "#000000",
                            },
                          },
                        },

                        yaxis: {
                          gridcolor: "gray",
                          zeroline: false,
                          line: {
                            color:
                              currentTheme === "dark" ? "#FFFFFF" : "#000000",
                          },
                          tickfont: {
                            color:
                              currentTheme === "dark" ? "#FFFFFF" : "#000000",
                          },
                          autorange: true,
                          linecolor:
                            currentTheme === "dark" ? "#FFFFFF" : "#000000",
                          showgrid: true,
                          title: {
                            text: `${selectedOption}`,
                            font: {
                              family:
                                '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
                              size: 14,
                              color:
                                currentTheme === "dark" ? "#FFFFFF" : "#000000",
                            },
                          },
                        },
                        plot_bgcolor:
                          currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                        paper_bgcolor:
                          currentTheme === "dark" ? "#292E33" : "white",
                      }}
                      config={{ displayModeBar: false, scrollZoom: false }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          toastStyle={{
            marginTop: "4rem",
            borderRadius: "10px",
            backgroundColor: "white",
            alignContent: "center",
            height: 50,
            margin: 10,
            width: 300,
            closeOnClick: true,
            textAlign: "center",
          }}
          closeButton={false}
          hideProgressBar={false}
          autoClose={2000}
          position="top-center"
          className="toast-container"
          toastClassName="dark-toast"
        />
      </div>



    </>
  );
}

export default SessionComparision2;
