import React, { useContext } from "react";
import UserTabLayout from "../../../../TabLayout/UserTabLayout";
import App from "../../Admin/UsersAdmin";
import { ThemePreferenceContext} from "../../../../../index";
import UserSuperAdmin from "./UserSuperAdmin";
import UserSuperAdminActive from "./UserSuperAdminActive";

function SuperUserAdminWrapper() {
  const { currentTheme } = useContext(ThemePreferenceContext);
  const menuItems = [
    {
      name: "active users",
      label: "Active Users",
      content: <UserSuperAdminActive />,
    },
    {
      name: "inactive users",
      label: "Inactive Users",
      content: <UserSuperAdmin />,
    },
  ];

  return (
    <div data-theme={currentTheme} style={{ marginTop: "2rem" }}>
      <UserTabLayout
        menuItems={menuItems}
        defaultTab="active users"
      ></UserTabLayout>
    </div>
  );
}

export default SuperUserAdminWrapper;
