import React, { useContext } from "react";
import TabLayout from "../../../../TabLayout/TabLayout";
import Dashboard from "../../Home/Dashboard";
import AuthContext from "../../../../../hooks/useAuth";

import { ThemePreferenceContext, themesMap } from "../../../../../index";
import OrganizationDashboardSuperAdmin from "./OrganizationDashboardSuperAdmin";

function HomeWrapperSuperAdmin() {
  const auth = useContext(AuthContext);
  const role = auth.role;
  let roleFlag = role === "admin" || "super_admin";
  const { currentTheme } = useContext(ThemePreferenceContext);
  const menuItems = [
    {
      name: "dashboard",
      label: "My Dashboard",
      content: <Dashboard />,
    },
    {
      name: "organization",
      label: "Organization Dashboard",
      content: <OrganizationDashboardSuperAdmin />,
      disableClick: !roleFlag,
    },
  ];

  return (
    <div data-theme={currentTheme} style={{ marginTop: "2rem" }}>
      <TabLayout menuItems={menuItems} defaultTab="dashboard"></TabLayout>
    </div>
  );
}

export default HomeWrapperSuperAdmin;
